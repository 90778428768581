import React, { useContext, useEffect, useState, useRef} from 'react';
import axios from 'axios';
import { UserContext } from '../../context/UserProvider';
import { useParams, useNavigate } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Swal from 'sweetalert2'
import PropiedadesRecomendadas from './propiedadesrecomendadas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faShareNodes, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormatoNumero } from '../global-components/globalfunctions';
import { faBus, faTrain, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { useReactToPrint } from 'react-to-print';


const MapComponent = ({ mapCenter, property, google }) => {
    const circleOptions = {
        center: new google.maps.LatLng(mapCenter.lat, mapCenter.lng),
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
    };
    const zoomLevel = property && property[0].direccionaprox ? 17 : 15;

    useEffect(() => {
        const map = new google.maps.Map(document.getElementById('map'), {
            zoom: zoomLevel,
            center: mapCenter,
        });

        let newCircle = null;
        let newMarker = null;
        let newInfoWindow = null;

        if (property) {
            if (property[0].direccionaprox) {
                newCircle = new google.maps.Circle({
                    ...circleOptions,
                    radius: 100,
                });
                newCircle.setMap(map);

                newInfoWindow = new google.maps.InfoWindow({
                    position: new google.maps.LatLng(mapCenter.lat, mapCenter.lng),
                });
                newInfoWindow.setContent(property[0].direccionaprox);
                newInfoWindow.open(map);
            } else {
                newMarker = new google.maps.Marker({
                    position: mapCenter,
                    map: map,
                });

                newInfoWindow = new google.maps.InfoWindow({
                    content: property[0].direccion,
                });
                newInfoWindow.open(map, newMarker);
            }
        }

        return () => {
            if (newCircle) {
                newCircle.setMap(null);
            }
            if (newMarker) {
                newMarker.setMap(null);
            }
            if (newInfoWindow) {
                newInfoWindow.close();
            }
        };
    }, []);

    return <div id="map" style={{ width: '100%', height: '400px' }} />;
};


const handleVisitsAndContacts = (idpropiedad, tipo) => {

    try {

        
        // Make a POST request to the API endpoint
       axios.post(`/api/propiedades/ActualizaVisitasyContactos?idpropiedad=${idpropiedad}&tipo=${tipo}`);


        // Handle success (e.g., display a success message)
        console.log(`Successfully updated ${tipo === 1 ? 'visitas' : 'contactos'}.`);
    } catch (error) {
        // Handle error (e.g., display an error message)
        console.error('Error updating visits/contacts:', error);
    }
};

const GuardaVisitasUsuario = (idpropiedad, idusuario) => {

    try {


        // Make a POST request to the API endpoint
        axios.post(`/api/propiedades/ActualizaVisitasUsuario?idpropiedad=${idpropiedad}&idusuario=${idusuario}`);

    } catch (error) {
        // Handle error (e.g., display an error message)
        console.error('Error updating visits/contacts:', error);
    }
};

function Detallepropiedad() {
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const { user } = useContext(UserContext);

    const { idpropiedad } = useParams();

    const [imageList, setImageList] = useState([]);
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [mensaje, setMensaje] = useState('');
    const [nombrecompleto, setNombreCompleto] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [searchparam, setSearch] = useState(null);
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [nearbyPlaces, setNearbyPlaces] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const componentRef = useRef(null);
  
    // Initialize useReactToPrint
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Propiedad_${property?.[0]?.idpropiedad}.pdf`,
        onAfterPrint: () => console.log('Print successful'),
    });

    const handlePrintWithCheck = () => {
        if (componentRef.current) {
            handlePrint(); // Call the print function only if the ref is set
        } else {
            console.log('Component reference not available');
        }
    };

    let imagealt;
    const navigate = useNavigate();

    

    const typeTranslations = {

        Educacion: ['school', 'university', 'primary_school','secondary_school'],
        Transporte: ['transit_station', 'train_station', 'airport', 'bus_station', 'light_rail_station', 'subway_station','taxi_stand'],
        Compras: ['supermarket','shoe_store','pet_store','movie_rental','liquor_store','jewelry_store','store', 'shopping_mall', 'beauty_salon', 'bicycle_store', 'library', 'book_store', 'bakery', 'clothing_store', 'convenience_store', 'electronics_store', 'florist', 'furniture_store', 'hardware_store','home_goods_store'],
        Salud: ['hospital', 'pharmacy','drugstore'],
        Servicios: ['veterinary_care','travel_agency','synagogue','storage','roofing_contractor','real_estate_agency','post_office','police','plumber','physiotherapist','parking','painter','moving_company','mosque','meal_takeaway','meal_delivery','locksmith','local_government_office','lawyer','laundry','accounting', 'atm', 'bank', 'campground', 'cemetery', 'church', 'city_hall', 'courthouse', 'dentist', 'electrician', 'embassy', 'fire_station', 'funeral_home', 'gym', 'hair_care', 'hindu_temple','insurance_agency'],
        Entretenimiento: ['spa', 'night_club', 'movie_theater', 'amusement_park', 'bowling_alley', 'aquarium', 'casino', 'zoo', 'rv_park'],
        Cultura: ['art_gallery', 'museum', 'stadium','tourist_attraction'],
        BaresRestaurantes: ['bar', 'cafe','restaurant'],
        Servicios_Autos: ['car_dealer', 'car_rental', 'car_repair', 'car_wash'],
        Gasolineras: ['gas_station'],
        Parques: ['park'],
    };

    const fetchProperties = (idpropiedad) => {
        axios
            .get(`/api/propiedades/ObtieneDetProp/${idpropiedad}`)
            .then((response) => {
                const properties = response.data;
                if (properties.length === 0) {
                    Swal.fire('Aviso', '¡La propiedad ya no se encuentra disponible en el sitio!', 'info');
                    navigate(`/`);
                    return;
                }

                const propertyPromises = properties.map(async (property) => {
                    const imagenResponse = await axios.get(`/api/images/ObtieneImagen/${property.imageid}`);
                    const base64Data = imagenResponse.data.filecontent;

                    const UsuarioResponse = await axios.get(`/api/usuario/ObtieneUsuario/${property.idusuario}`);
                    const nombre = UsuarioResponse.data.usuario.nombre;
                    const correo = UsuarioResponse.data.usuario.correo;
                    const telefono = UsuarioResponse.data.usuario.telefono;

                    const icono = UsuarioResponse.data.icono;

                    const reseñasResponse = await axios.get(`/api/resenas/getRating/${property.idusuario}`);
                    const averageRating = reseñasResponse.data.averageRating;
                    const reviewCount = reseñasResponse.data.reviewCount;


                    return { ...property, base64Data, nombre, correo, telefono, icono, averageRating, reviewCount };
                });

                Promise.all(propertyPromises)
                    .then((updatedProperties) => {
                        setProperty(updatedProperties);

                        let estacionamientos = '';
                        if (updatedProperties[0].numestacionamientos >= 1 && updatedProperties[0].numestacionamientos <= 2) {
                            estacionamientos = '1-2';
                        }
                        else if (updatedProperties[0].numestacionamientos >= 3 && updatedProperties[0].numestacionamientos <= 4) {
                            estacionamientos = '3-4';
                        }
                        else if (updatedProperties[0].numestacionamientos >= 5 && updatedProperties[0].numestacionamientos <= 6) {
                            estacionamientos = '5-6';
                        }
                        else if (updatedProperties[0].numestacionamientos >= 7) {
                            estacionamientos = 'Minimo 7';
                        }

                        let baños = '';
                        if (updatedProperties[0].numbaños >= 1 && updatedProperties[0].numbaños <= 2) {
                            baños = '1-2';
                        }
                        else if (updatedProperties[0].numbaños >= 3 && updatedProperties[0].numbaños <= 4) {
                            baños = '3-4';
                        }
                        else if (updatedProperties[0].numbaños >= 5 && updatedProperties[0].numbaños <= 6) {
                            baños = '5-6';
                        }
                        else if (updatedProperties[0].numbaños >= 7) {
                            baños = 'Minimo 7';
                        }

                        let habitaciones = '';
                        if (updatedProperties[0].numhabitaciones >= 1 && updatedProperties[0].numhabitaciones <= 2) {
                            habitaciones = '1-2';
                        }
                        else if (updatedProperties[0].numhabitaciones >= 3 && updatedProperties[0].numhabitaciones <= 4) {
                            habitaciones = '3-4';
                        }
                        else if (updatedProperties[0].numhabitaciones >= 5 && updatedProperties[0].numhabitaciones <= 6) {
                            habitaciones = '5-6';
                        }
                        else if (updatedProperties[0].numhabitaciones >= 7) {
                            habitaciones = 'Minimo 7';
                        }

                        let tipopublicacion = '';
                        if (updatedProperties[0].tipopublicacion === 1)
                            tipopublicacion = "Comprar"
                        else
                            tipopublicacion = "Arrendar"


                        // Set searchparam state after properties are fetched
                        setSearch((prevSearch) => ({
                            ...prevSearch,
                            tipopublicacion: tipopublicacion,
                            tipopropiedad: updatedProperties[0].tipopropiedad.toString(),
                            busqueda: "",
                            rangodesdeprecio: '0',
                            rangohastaprecio: updatedProperties[0].precioactual.toString(),
                            rangodesdetamaño: '0',
                            rangohastatamaño: updatedProperties[0].area.toString(),
                            estacionamientos: estacionamientos,
                            baños: baños,
                            habitaciones: habitaciones,
                            idusuario: null,
                            moneda: updatedProperties[0].moneda,
                            comuna: updatedProperties[0].comuna,
                            Relacionada: true
                        }));
                        setLoading(false);
                        handleVisitsAndContacts(updatedProperties[0].idpropiedad, 1);
                        if (user) GuardaVisitasUsuario(updatedProperties[0].idpropiedad, user.usuario.idusuario);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);

                    });
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };


        const [busStops, setBusStops] = useState([]);
        const [trainStations, setTrainStations] = useState([]);
        const [colegios, setColegios] = useState([]);

        useEffect(() => {
            const fetchData = async () => {
                try {
                    if (property.lenght === 0) return
                    const query = `[out:json]; 
                   (
                     node(around:1000,${property[0].latitud},${property[0].longitud})["highway"="bus_stop"];
                     node(around:1000,${property[0].latitud},${property[0].longitud})["railway"="station"];
                     node(around:1000,${property[0].latitud},${property[0].longitud})["amenity"="school"];
                   );
                   out;`;

                    const response = await axios.post('https://overpass-api.de/api/interpreter', query);

                    if (response.data && response.data.elements) {
                        const elements = response.data.elements;
                        const busStops = elements.filter(elem => elem.tags && elem.tags.highway);
                        const trainStations = elements.filter(elem => elem.tags && elem.tags.railway);
                        const colegios = elements.filter(elem => elem.tags && elem.tags.amenity === 'school');

                        setBusStops(busStops.slice(0, 5));
                        setTrainStations(trainStations.slice(0, 5));
                        setColegios(colegios.slice(0, 5));
                    } else {
                        throw new Error('No se encontraron datos de transporte.');
                    }
                } catch (error) {
                    console.error('Error al obtener datos de transporte: ', error);
                    throw new Error('Ocurrió un error al obtener datos de transporte.');
                }
            };
            if (property) {
                fetchData();
            }
        }, [property]);

    const renderTransportList = (transportList, type) => {
        let icon;
        if (type === 'Paraderos de Bus') {
            icon = faBus;
        } else if (type === 'Metro/Tren') {
            icon = faTrain;
        } else if (type === 'Colegios') {
            // Assume you have an appropriate FontAwesome icon for schools
            icon = faGraduationCap;
        }

        return (
            <div className="transport-column">
                <div className="icon">
                    <FontAwesomeIcon icon={icon} />  {type}
                </div>
                {/*<h2>{type}</h2>*/}
                <ul>
                    {transportList.map((transport, index) => (
                        <li key={index}>
                            {transport.tags && transport.tags.name ? transport.tags.name : 'Sin nombre'}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };


    const fetchImages = async (idpropiedad) => {
        try {
            const response = await fetch(`/api/images/ObtieneImagenes/${idpropiedad}`);
            const data = await response.json();
            setImageList(data);
        } catch (error) {
            console.log('Error fetching images:', error);
        }
    };

    useEffect(() => {
        fetchProperties(idpropiedad);
        // Scroll to the top when the page is loaded
        window.scrollTo(0, 0);

    }, [idpropiedad]);

    useEffect(() => {
        if (property != null && !isNaN(property[0].latitud) && !isNaN(property[0].longitud)) {
            setMapCenter({ lat: property[0].latitud, lng: property[0].longitud });
        }
    }, [property]);

    useEffect(() => {
        fetchImages(idpropiedad);
    }, [idpropiedad]);

    if (loading === true || window.google === undefined || property == null) {
        return <p></p>;
    }

    let images = [];
    if (imageList && Array.isArray(imageList)) {
        images = imageList.map((image) => ({
            src: `data:image/png;base64,${image.filecontent}`,
            thumbnail: `data:image/png;base64,${image.filecontent}`,
            caption: image.fileName,
        }));
    }

    
    const sliderImageStyles = {
        width: '800px',
        height: '500px',
        objectFit: 'cover',
        margin: '0 auto',
    };

    const openLightbox = (index) => {
        setSelectedImageIndex(index);
    };

    const closeLightbox = () => {
        setSelectedImageIndex(null);
    };



    const sendEmail = async (mensaje, prop) => {
        try {

            if (!user && (!email || !nombrecompleto)) {
                Swal.fire('Aviso', 'Debe llenar la casilla nombre completo y email', 'info');
                return;
            }

            let tiendadeshab = 0;
            const responsecliente = await axios.get(`/api/clientes/getCliente/${property[0].idcliente}`);


            if (responsecliente.status === 200) {
                const clienteTargetData = responsecliente.data;
                tiendadeshab = clienteTargetData[0].tiendadeshabilitada;
            }

            const emailRequest = {
                correofrom: user ? user.usuario.correo : email,
                correoto: property[0].correo,
                propiedad: property[0],
                mensaje: mensaje,
                nombrecontacto: property[0].nombre,
                nombreusuario: user ? user.usuario.nombre : nombrecompleto,
                telefono: user ? user.usuario.telefono : telefono,
                url: window.location.href.toString(),
                tiendadeshabilitada: tiendadeshab
            };

            const response = await axios.post('/api/propiedades/EnviaEmail', emailRequest);

            if (response.status === 200) {
                Swal.fire('Aviso', 'Correo enviado exitosamente al contacto de la propiedad', 'info');
                handleVisitsAndContacts(property[0].idpropiedad, 2);
            }
            else {
                Swal.fire('Error', 'Correo no puedo ser enviado al contacto de la propiedad', 'error');
            }

        } catch (error) {
            Swal.fire('Error', 'Correo no puedo ser enviado al contacto de la propiedad', 'error');
            console.error(error);
            // Handle error
        }
    };

    const handleMensaje = (prop) => {
        sendEmail(mensaje, prop);
        // Send event to Google Analytics
        window.gtag('event', 'mensajecontacto', {
            'event_category': 'Button',
            'event_label': 'ContactodeUsuario'
        });
    };

    const handleShareIconClick = () => {
        setShowShareOptions(!showShareOptions);
    };

    const handleShareOptionClick = (option) => {
        const propertyUrl = window.location.href;

        if (option === 'whatsapp') {
            window.gtag('event', 'mensajecontacto', {
                'event_category': 'Button',
                'event_label': 'ContactodeUsuario'
            });
            const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(propertyUrl)}`;
            window.open(whatsappUrl, '_blank');
        } else if (option === 'email') {
            window.gtag('event', 'mensajecontacto', {
                'event_category': 'Button',
                'event_label': 'ContactodeUsuario'
            });
            const emailUrl = `mailto:?body=${encodeURIComponent(propertyUrl)}`;
            window.location.href = emailUrl;
        }

        setShowShareOptions(false);
    };


    const handleNearbyPlacesLoaded = (results) => {
        setNearbyPlaces(results);
    };

   let subtitulo = "";
    if (property[0].tipopropiedad === 2) subtitulo = subtitulo + "Casa en ";
    if (property[0].tipopropiedad === 3) subtitulo = subtitulo + "Departamento en ";
    if (property[0].tipopropiedad === 4) subtitulo = subtitulo + "Galpon en ";
    if (property[0].tipopropiedad === 5) subtitulo = subtitulo + "Local Comercial en ";
    if (property[0].tipopropiedad === 6) subtitulo = subtitulo + "Terreno en ";
    if (property[0].tipopropiedad === 7) subtitulo = subtitulo + "Oficina en ";
    if (property[0].tipopropiedad === 8) subtitulo = subtitulo + "Parcela en ";
    if (property[0].tipopropiedad === 9) subtitulo = subtitulo + "Bodega en ";
    if (property[0].tipopropiedad === 10) subtitulo = subtitulo + "Derecho Llave en ";
    if (property[0].tipopropiedad === 11) subtitulo = subtitulo + "Estacionamiento en ";
    if (property[0].tipopublicacion === 1) subtitulo = subtitulo + "Venta";
    if (property[0].tipopublicacion === 2) subtitulo = subtitulo + "Arriendo";

    return (
        <div ref={componentRef} className="property-details-area">
            {/* Render spinner if loading */}
            {loading && (
                <div className="preloader" id="preloader">
                    <div className="preloader-inner">
                        <div className="spinner">
                            <div className="dot1"></div>
                            <div className="dot2"></div>
                        </div>
                    </div>
                </div>
            )}
            <div className="bg-gray pd-top-60 pd-bottom-30">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-9 col-lg-8">
                            <div className="" style={{ display: 'flex', justifyContent: 'center' }}>
                                {property[0].nivelpromo && (
                                    <img
                                        src="/assets/img/icons/promo-icon-9.png"
                                        alt="Promo"
                                        style={{ width: '30px', height: '30px' }}
                                    />
                                )}
                                <h3>
                                    <span>{property[0].titulo} (Id:{property[0].idpropiedad})</span>
                                </h3>
                            </div>
                            <div className="property-details-slider">
                                <Swiper
                                    modules={[Navigation, Pagination]}
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    navigation
                                    pagination={{ clickable: true }}
                                >
                                    {images.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="slider-image-container">
                                                <div className="image-wrapper">
                                                    <img
                                                        src={image.src}
                                                        alt={image.caption}
                                                        style={sliderImageStyles}
                                                        onClick={() => openLightbox(index)}
                                                    />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                            {selectedImageIndex !== null && (
                                <Lightbox
                                    mainSrc={images[selectedImageIndex].src}
                                    onCloseRequest={closeLightbox}
                                    reactModalStyle={{
                                        overlay: { zIndex: 9999 },
                                        content: { maxWidth: '800px' },
                                    }}
                                />
                            )}
                            <div className="property-details-slider-info">
                                <h3>
                                    <span style={{color: 'black' }}>{subtitulo}</span>
                                </h3>
                            </div>
                            <div className="property-details-slider-info">
                                <h3>
                                    <span style={{ marginRight: '10px' }}>{property[0].moneda}</span>
                                    {FormatoNumero(property[0].precioactual)}
                                    <del style={{ marginLeft: '30px' }}>{FormatoNumero(property[0].precioAnterior)}</del>
                                </h3>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4">

                            <div className="widget-social-icons text-center btn-yellow">
                                <FontAwesomeIcon
                                    icon={faShareNodes}
                                    style={{ color: "white", cursor: 'pointer' }}
                                    size="lg"
                                    onClick={handleShareIconClick}
                                />
                                {showShareOptions && (
                                    <div className="share-options">
                                        <div
                                            className="share-option"
                                            onClick={() => handleShareOptionClick('whatsapp')}
                                        >
                                            <FontAwesomeIcon
                                                icon={faWhatsapp}
                                                size="lg"
                                                style={{ marginRight: '5px' }}
                                            />
                                            WhatsApp
                                        </div>
                                        <div className="share-option" onClick={() => handleShareOptionClick('email')}>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                                size="lg"
                                                style={{ marginRight: '5px' }}
                                            />
                                            Email
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="text-center">
                            <br></br>
                            Exportar a
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    style={{ color: 'var(--main-color-one)', cursor: 'pointer', marginLeft: '10px'  }}
                                    size="lg"
                                    onClick={handlePrintWithCheck}
                                />
                            </div>
                            <br></br>
                            <div className="widget widget-owner-info mt-lg-0 mt-5">
                                <div className="owner-info text-center">
                                    <a href={user ? `/reseñas/${property[0].idusuario}` : "#"}>
                                        <div className="thumb" style={{ width: "80px", height: "80px" }}>
                                            {property[0].icono ? (
                                                <img
                                                    src={`data:image/jpeg;base64,${property[0].icono}`}
                                                    alt={imagealt}
                                                    style={{ objectFit: "contain", width: "100%", height: "100%" }}
                                                />
                                            ) : (
                                                <img
                                                    className="img-profile rounded-circle"
                                                    src={"/assets/img/Foto003.jpg"}
                                                    alt="logo"
                                                    style={{ objectFit: "contain", width: "100%", height: "100%" }}
                                                />
                                            )}
                                        </div>
                                    </a>
                                    <div className="details">
                                        <a href={user ? `/reseñas/${property[0].idusuario}` : "#"}>
                                            <h6>{property[0].nombre}</h6>
                                        </a>
                                        <span className="designation">Contacto</span>
                                        <a href={user ? `/reseñas/${property[0].idusuario}` : "#"}>
                                            <p className="reviews">
                                                <i className="fa fa-star" />
                                                <span>{property[0].averageRating ? property[0].averageRating.toFixed(1) : "0"}</span>
                                                <span>{property[0].reviewCount ? property[0].reviewCount : "0"} Reseña(s)</span>
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div className="contact">
                                    <h6 style={{ color: 'var(--main-color-one)', textAlign: 'center', fontWeight: 'bold' }}>¡Agenda tu visita aquí o solicita más información!</h6>
                                    {!user &&
                                        <div className="rld-single-input">
                                            <input type="text" placeholder="Nombre Completo"
                                                onChange={(event) => {
                                                    setNombreCompleto(event.target.value);
                                                }}

                                            />
                                        </div>
                                    }
                                    {!user &&
                                    <div className="rld-single-input">
                                            <input type="text" placeholder="Email"
                                                onChange={(event) => {
                                                    setEmail(event.target.value);
                                                }}
                                            />
                                    </div>
                                    }
                                    {!user &&
                                        <div className="rld-single-input">
                                            <input type="text" placeholder="Telefono"
                                                onChange={(event) => {
                                                    setTelefono(event.target.value);
                                                }}
                                            />
                                        </div>
                                    }
                                    <div className="rld-single-input">
                                        <textarea
                                            className="form-control"
                                            id="mensaje"
                                            rows="3"
                                            maxLength="250"
                                            placeholder="Mensaje"
                                            value={mensaje}
                                            onChange={(event) => {
                                                setMensaje(event.target.value);
                                            }}
                                            style={{ backgroundColor: '#F6F7FB' }}
                                        ></textarea>
                                    </div>
                                    <button className="btn btn-yellow"
                                        onClick={() => handleMensaje(property[0])}>
                                        Mandar Mensaje
                                    </button>
                                </div>
                                <div className="contact-info">
                                    <h6 className="mb-3">Info Contacto</h6>
                                    <div className="media">
                                        <div className="media-left">
                                            <i className="fa fa-phone" />
                                        </div>
                                        <div className="media-body">
                                            <p>Telefono</p>
                                            <a href={`tel:${property[0].telefono}`}>
                                                <span>{property[0].telefono}</span>
                                            </a>
                                            <a
                                                href={`https://wa.me/${property[0].telefono}?text=${encodeURIComponent(
                                                    `Hola, estoy interesado en esta propiedad que encontré en el portal buscadorpropiedades: Link propiedad: https://${window.location.host}${window.location.pathname}`
                                                )}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ marginLeft: '10px' }}
                                            >
                                                <FontAwesomeIcon icon={faWhatsapp} />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="media mb-0">
                                        <div className="media-left">
                                            <i className="fa fa-envelope" />
                                        </div>
                                        <div className="media-body">
                                            <p>Email</p>
                                            <span>{property[0].correo}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row pd-top-60">
                    <div className="col-lg-9">
                        <div className="property-info mb-3">
                            <div className="row">
                                <div className="col-md-3 col-sm-6">
                                    <div className="single-property-info">
                                        <h5>habitaciones</h5>
                                        <p>
                                            <i className="fa fa-bed" />
                                            {property[0].numhabitaciones}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="single-property-info">
                                        <h5>baños</h5>
                                        <p>
                                            <i className="fa fa-bath" />
                                            {property[0].numbaños}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="single-property-info">
                                        <h5>Superficie</h5>
                                        <p>
                                            <img src={'/assets/img/icons/7.png'} alt={imagealt} />
                                            {property[0].area} Mt2
                                        </p>
                                    </div>
                                </div>
                                {property[0].areaUtil && (
                                <div className="col-md-3 col-sm-6">
                                    <div className="single-property-info">
                                        <h5>Superficie Util</h5>
                                        <p>
                                            <img src={'/assets/img/icons/7.png'} alt={imagealt} />
                                            {property[0].areaUtil} Mt2
                                        </p>
                                    </div>
                                </div>
                                )}
                                <div className="col-md-3 col-sm-6">
                                    <div className="single-property-info">
                                        <h5>estacionamientos</h5>
                                        <p>
                                            <i className="fa fa-car" />
                                            {property[0].numestacionamientos}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="property-news-single-card style-two border-bottom-yellow">
                            <h4>descripcion</h4>
                            <textarea style={{ width: '100%', height: '400px' }} readOnly maxLength="5000" value={property[0].descripcion}>}</textarea>
                        </div>
                        <div className="property-news-single-card style-two border-bottom-yellow">
                            <h4>Ubicacion</h4>
                            <h5>comuna: {property[0].comuna}</h5>
                            <div>
                                {showMap ? (
                                    <div style={{ width: '100%', height: '400px' }}>
                                        {window.google && (
                                            <MapComponent
                                                mapCenter={mapCenter}
                                                property={property}
                                                google={window.google}
                                                onNearbyPlacesLoaded={handleNearbyPlacesLoaded}
                                                typeTranslations={typeTranslations}
                                            />
                                        )}
                                    </div>
                                ) : (
                                     <img
                                            src={"/assets/img/feature/gmaps.jpg"}
                                            alt="imagenmapa"
                                            onClick={() => setShowMap(true)} // Show map on image click
                                            style={{ width: '100%', height: 'auto', cursor: 'pointer' }} // Inline styles for the image
                                        >
                                    </img>
                                )}
                            </div>
                        </div>
                        {showMap && (
                            <div className="transport-container">
                                {busStops.length > 0 && renderTransportList(busStops, 'Paraderos de Bus')}
                                {trainStations.length > 0 && renderTransportList(trainStations, 'Metro/Tren')}
                                {colegios.length > 0 && renderTransportList(colegios, 'Colegios')}
                            </div>
                        )}
                        <div className="property-news-single-card border-bottom-yellow">
                            <h4>Incluye</h4>
                            <div className="row">
                                <div className="col-sm-4">
                                    <ul className="rld-list-style mb-3 mb-sm-0">
                                        {property[0].tienepiscina ? (
                                            <li>
                                                <i className="fa fa-check" /> Piscina
                                            </li>
                                        ) : (
                                            <li>
                                                <i className="fa fa-uncheck" /> Piscina
                                            </li>
                                        )}
                                        {property[0].tienegimnasio ? (
                                            <li>
                                                <i className="fa fa-check" /> Gimnasio
                                            </li>
                                        ) : (
                                            <li>
                                                    <i className="fa fa-uncheck" /> Gimnasio
                                            </li>
                                        )}
                                        {property[0].tienequincho ? (
                                            <li>
                                                <i className="fa fa-check" /> Quincho
                                            </li>
                                        ) : (
                                            <li>
                                                <i className="fa fa-uncheck" /> Quincho
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className="col-sm-4">
                                    <ul className="rld-list-style mb-3 mb-sm-0">
                                        {property[0].tienecalefaccion ? (
                                            <li>
                                                <i className="fa fa-check" /> Calefaccion
                                            </li>
                                        ) : (
                                            <li>
                                                <i className="fa fa-uncheck" /> Calefaccion
                                            </li>
                                        )}
                                        {property[0].tienecamaras ? (
                                            <li>
                                                <i className="fa fa-check" /> Camaras
                                            </li>
                                        ) : (
                                            <li>
                                                <i className="fa fa-uncheck" /> Camaras
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className="col-sm-4">
                                    <ul className="rld-list-style mb-3 mb-sm-0">
                                        {property[0].tienebodega ? (
                                            <li>
                                                <i className="fa fa-check" /> Bodega
                                            </li>
                                        ) : (
                                            <li>
                                                <i className="fa fa-uncheck" /> Bodega
                                            </li>
                                        )}
                                        {property[0].tienecanchatenis ? (
                                            <li>
                                                <i className="fa fa-check" /> Cancha de Tenis
                                            </li>
                                        ) : (
                                            <li>
                                                <i className="fa fa-uncheck" /> Cancha de Tenis
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {user && property[0].idusuario === user.usuario.idusuario && (
                            <div className="property-news-single-card border-bottom-yellow">
                                <h4>Datos Visitas</h4>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <p>Número de Visitas: {property ? property[0].numvisitas : 'Loading...'}</p>
                                        <p>Número de Contactos: {property ? property[0].numContactos : 'Loading...'}</p>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
            {property &&
                <PropiedadesRecomendadas
                    key={1}
                    searchparm={searchparam}
                    propertyzero={property[0]}
                />
            }
        </div>
    );
}

export default Detallepropiedad;
