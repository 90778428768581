import { Link, useLocation, useNavigate} from 'react-router-dom';
import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import axios from 'axios';
import { Button} from 'reactstrap';
import PropiedadesRecomendadas from './propiedadesrecomendadas';
import { UserContext } from "../../context/UserProvider"
import Swal from 'sweetalert2'
import Toolbar from '../global-components/toolbar'
import { FormatoNumero } from '../global-components/globalfunctions';

function Thumb({ imageData, height, hasPromo, idpropiedad }) {
    return (
        <Link to={`/detallepropiedad/${idpropiedad}`}>
        <div className="thumb" >
            <div className="thumb-image" style={{ position: 'relative' }}>
                {hasPromo && (
                    <div className="promo-icon" style={{ position: 'absolute', top: 15, left: 15 }}>
                        <img src={'/assets/img/icons/promo-icon-9.png'} alt="Promo" style={{ width: '40px', height: '40px' }} />
                    </div>
                )}
                <img
                    src={`data:image/jpeg;base64,${imageData}`}
                    alt="img"
                    style={{ width: '100%', height: `${height}px` }}
                />
            </div>
            </div>
        </Link>
    );
}

const toggleFavorite = async (property,dt) => {
    try {
        const heartIcon = document.getElementById(`heartIcon-${property.property.idpropiedad}`);

        if (heartIcon) {
            const isSelected = heartIcon.classList.contains('selected');

            if (!isSelected) {
                const url = `/api/propiedades/marcafavorito/${property.property.idpropiedad}/${dt.usuario.idusuario}/${1}`;
                const requestBody = null;
                const headers = {
                    'Content-Type': 'application/json',
                };

                const response = await axios.post(url, requestBody, { headers });
                console.log('Response:', response);
                heartIcon.classList.add('selected');
                heartIcon.style.color = 'red';
            } else {
                const url = `/api/propiedades/marcafavorito/${property.property.idpropiedad}/${dt.usuario.idusuario}/${0}`;
                const requestBody = null;
                const headers = {
                    'Content-Type': 'application/json',
                };

                const response = await axios.post(url, requestBody, { headers });
                console.log('Response:', response);
                heartIcon.classList.remove('selected');
                heartIcon.style.color = ''; // Reset to the default color or define your desired color here
            }
        }
    } catch (error) {
        console.log('Error:', error);
    }
};

const renderHeartIcon = (property,dt) => {
    if (property.isFavorite) {
        return (
            <i
                id={`heartIcon-${property.property.idpropiedad}`}
                className="fa fa-heart selected"
                style={{ color: 'red' }}
                onClick={() => toggleFavorite(property,dt)}
            />
        );
    } else {
        return (
            <i
                id={`heartIcon-${property.property.idpropiedad}`}
                className="fa fa-heart"
                onClick={() => toggleFavorite(property,dt)}
            />
        );
    }
};

function Details(item,dt) {
    let subtitulo = "";
    if (item.property.tipopropiedad === 2) subtitulo = subtitulo + "Casa en ";
    if (item.property.tipopropiedad === 3) subtitulo = subtitulo + "Departamento en ";
    if (item.property.tipopropiedad === 4) subtitulo = subtitulo + "Galpon en ";
    if (item.property.tipopropiedad === 5) subtitulo = subtitulo + "Local Comercial en ";
    if (item.property.tipopropiedad === 6) subtitulo = subtitulo + "Terreno en ";
    if (item.property.tipopropiedad === 7) subtitulo = subtitulo + "Oficina en ";
    if (item.property.tipopropiedad === 8) subtitulo = subtitulo + "Parcela en ";
    if (item.property.tipopropiedad === 9) subtitulo = subtitulo + "Bodega en ";
    if (item.property.tipopropiedad === 10) subtitulo = subtitulo + "Derecho Llave en ";
    if (item.property.tipopropiedad === 11) subtitulo = subtitulo + "Estacionamiento en ";
    if (item.property.tipopublicacion === 1) subtitulo = subtitulo + "Venta";
    if (item.property.tipopublicacion === 2) subtitulo = subtitulo + "Arriendo";

    return (
        <div>
            <div  className="feature-logo">
                {item.icono &&
                    <img src={`data:image/jpeg;base64,${item.icono}`}
                    alt="logo" className="rounded-circle img-thumbnail thumbnail-icon"
                    style={{ Width: '45px', height: '45px' }}/>
                }
                {!item.icono &&
                    <img className="img-profile rounded-circle" src={'/assets/img/Foto003.jpg'} alt="logo"/>    
                }
            </div>
            {dt && (
                <div className="favorite-icon">
                    {renderHeartIcon(item,dt)}
                </div>
            )}
            <p className="author">
                <i className="fa fa-user" /> {item.nombre}
            </p>
            <h6 className="readeal-top">
                {subtitulo}
            </h6>
            <h6 className="title readeal-top">
                {item.property.titulo} - {item.property.comuna}
            </h6>
            <div>
                <label className="price" style={{ fontSize: '14px' }}>
                    <span >{FormatoNumero(item.property.precioactual)}</span>
                    {' '}
                    <span className="moneda" >{item.property.moneda}</span>
                </label>
                <br></br>
                <del className="precioanterior">{FormatoNumero(item.property.precioAnterior)}</del>
            </div>
            <div className="info-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    {item.property.numhabitaciones > 0 && (
                        <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                            <i className="fa fa-bed" style={{ marginRight: '5px', color: 'var(--main-color-three)' }} />
                            <span>{item.property.numhabitaciones} Habs</span>
                        </div>
                    )}
                    {item.property.numestacionamientos > 0 && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="fa fa-car" style={{ marginRight: '5px', color: 'var(--main-color-three)' }} />
                            <span>{item.property.numestacionamientos} Estac.</span>
                        </div>
                    )}
                </div>
                <div style={{ textAlign: 'right' }}>
                    {item.property.numbaños > 0 && (
                        <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                            <i className="fa fa-bath" style={{ marginRight: '5px', color: 'var(--main-color-three)' }} />
                            <span>{item.property.numbaños} baños</span>
                        </div>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={'/assets/img/icons/area.png'} alt="area" style={{ marginRight: '10px', filter: 'var(--main-color-three)' }} />
                        <span>{item.property.area} Mt2</span>
                    </div>
                </div>
            </div>
            <div style={{ width: '150px', borderBottom: '2px solid ', margin: '0 auto 10px', marginTop: '20px' }} />
            <div className="info-container" style={{ alignItems: 'center', fontWeight: 'bold', fontSize: '13px' }}>
                {item.cercanosData}
            </div>
            <ul className="contact-list">
                <li className="readeal-top">
                    <Link className="btn btn-yellow" to={`/detallepropiedad/${item.property.idpropiedad}`}>
                        Ver Detalles
                    </Link>
                </li>
            </ul>
        </div>
    );
}

const circles = [];

const MyMapComponent = ({ properties }) => {
    const [selectedProperty, setSelectedProperty] = useState(null);
    // Initialize an array to store circles

    const navigate = useNavigate();

    const handleMarkerClick = (property) => {
    setSelectedProperty(property);
    };

    const closePropertyDetails = () => {
        setSelectedProperty(null);
    };

    useEffect(() => {
        const firstProperty = properties[0];

        const initMap = () => {
            const mapCenter = {
                lat: firstProperty ? firstProperty.property.latitud : 0,
                lng: firstProperty ? firstProperty.property.longitud : 0,
            };

            const map = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 12,
                center: mapCenter,
            });

            properties.forEach((property, index) => {
                const marker = new window.google.maps.Marker({
                    position: { lat: property.property.latitud, lng: property.property.longitud },
                    map: map,
                });

                marker.addListener('click', () => handleMarkerClick(property));

                const circle = new window.google.maps.Circle({
                    center: { lat: property.property.latitud, lng: property.property.longitud },
                    radius: 500,
                    fillColor: 'red',
                    fillOpacity: 0.4,
                    strokeColor: 'red',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    map: map,
                });

                // Store the circle in the 'circles' array
                circles.push(circle);
            });

            return () => {
                document.getElementById('map').innerHTML = ''; // Clear the map element
            };
        };

        initMap(); // Call the initMap function directly

        // Clean up any resources if needed
    }, [properties]);

    const handleVerDetalles = (property) => {
        const url = `/detallepropiedad/${property.property.idpropiedad}`;
        navigate(url, { replace: true });
    };

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div id="map" style={{ height: '100%', position: 'relative', width: '100%' }} />
            {selectedProperty && (
                <div
                    style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        padding: '10px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                        width: '270px',
                        height: '465px',
                        zIndex: '100',
                        overflow: 'hidden',
                        top: 50,
                        left: 50,
                    }}
                >
                    <Thumb imageData={selectedProperty.base64Data} height={100} />
                    <div className="property-info">
                        <div className="single-feature style-two">
                            <a href="/" className="custom-logo">
                                {selectedProperty.icono && (
                                    <img src={`data:image/jpeg;base64,${selectedProperty.icono}`} alt="logo" />
                                )}
                                {!selectedProperty.icono && (
                                    <img
                                        className="img-profile rounded-circle"
                                        src={'/assets/img/Foto003.jpg'}
                                        alt="logo"
                                    />
                                )}
                            </a>
                            <p className="author">
                                <i className="fa fa-user" /> {selectedProperty.nombre}
                            </p>
                            <h6 className="readeal-top">
                                {selectedProperty.property.tipopropiedad === 2 && 'Casa en '}
                                {selectedProperty.property.tipopropiedad === 3 && 'Departamento en '}
                                {selectedProperty.property.tipopropiedad === 4 && 'Galpon en '}
                                {selectedProperty.property.tipopropiedad === 5 && 'Local Comercial en '}
                                {selectedProperty.property.tipopropiedad === 6 && 'Terreno en '}
                                {selectedProperty.property.tipopropiedad === 7 && 'Oficina en '}
                                {selectedProperty.property.tipopropiedad === 8 && 'Parcela en '}
                                {selectedProperty.property.tipopropiedad === 9 && 'Bodega en '}
                                {selectedProperty.property.tipopropiedad === 10 && 'Derecho Llave en '}
                                {selectedProperty.property.tipopropiedad === 11 && 'Estacionamiento en '}
                                {selectedProperty.property.tipopublicacion === 1 ? 'Venta' : 'Arriendo'}
                            </h6>
                            <h6 className="readeal-top">
                                {selectedProperty.property.titulo} - {selectedProperty.property.comuna}
                            </h6>
                            <h6 className="price">
                                {selectedProperty.property.moneda} {selectedProperty.property.precioactual.toLocaleString()}
                            </h6>
                            <del>{selectedProperty.property.precioAnterior.toLocaleString()}</del>
                            <ul className="info-list">
                                <li>
                                    <i className="fa fa-bed" /> {selectedProperty.property.numhabitaciones} habitaciones
                                </li>
                                <li>
                                    <i className="fa fa-bath" /> {selectedProperty.property.numbaños} baños
                                </li>
                                <li>
                                    <i className="fa fa-car" /> {selectedProperty.property.numestacionamientos} estacionamientos
                                </li>
                                <li>
                                    <img src={'/assets/img/icons/7.png'} alt="area" /> {selectedProperty.property.area} Mt2
                                </li>
                            </ul>
                            <button className="btn btn-yellow"  onClick={() => handleVerDetalles(selectedProperty)}>Ver Detalles</button>
                            <button className="btn-lightblue"  onClick={closePropertyDetails}>Cerrar</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};


function BusquedaPropiedades() {

    const [tipovista, settipoVista] = useState('2');
    const [properties, setProperties] = useState([]);
    const [moneda, setMoneda] = useState('TODAS');
    const [habitaciones, sethabitaciones] = useState('1');
    const [habitacionestext, sethabitacionesText] = useState('Todas');
    const [baños, setbaños] = useState('1');
    const [bañostext, setbañosText] = useState('Todos');
    const [estacionamientos, setestacionamientos] = useState('1');
    const [estacionamientostext, setestacionamientosText] = useState('Todos');
    const [idpropiedadfilter, setidpropiedadfilter] = useState('');
    const [showMap, setShowMap] = useState(false);
    const [hoveredProperty, setHoveredProperty] = useState(null);
    const [showCard, setShowCard] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [propertiesPerPage, setPropertiesPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1); // Create an array of page numbers
    const [loading, setLoading] = useState(true);
    const [fetchComplete, setFetchComplete] = useState(false);
    const [showContent, setShowContent] = useState(true);
    const [showRecomendaciones, setShowRecomendaciones] = useState(true);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [sortOption, setSortOption] = useState('1'); // State for the selected sort option
    const [propertiesToDisplay, setPropertiesToDisplay] = useState([]);
    const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
    const [viewDropdownOpen, setViewDropdownOpen] = useState(false);
    const [perPageDropdownOpen, setPerPageDropdownOpen] = useState(false);
    const toggleButtonRef = useRef(null);


    const navigate = useNavigate();
    const location = useLocation();
    let Consulta = "";

    if (location.state) {
        Consulta = location.state.busqueda;
    }
    else {
        Consulta = "PromocionadosBanner"
    }
    

    let tipoprop = "";
    let tipopublicacionparm = "";
    let consultastr = "";
    let idclienteparm = "";
    let tipoconsulta = "";
    
    if (Consulta.trim().indexOf("idcliente-") !== -1) {
        let last = Consulta.lastIndexOf("-");
        tipopublicacionparm = "1";
        tipoprop = "1";
        consultastr = Consulta.substring(last + 1);
        idclienteparm = consultastr;
        consultastr = "";
        tipoconsulta = "Cliente";
    }
    else if (Consulta.trim().indexOf("PromocionadosBanner") !== -1) {
        tipopublicacionparm = "1";
        tipoprop = "1";
        consultastr = "";
        idclienteparm = null;
        tipoconsulta = "PromocionadosBanner";
    }
    else if (Consulta.trim().indexOf("Promocionados") !== -1) {
        tipopublicacionparm = "1";
        tipoprop = "1";
        consultastr = "";
        idclienteparm = null;
        tipoconsulta = "Promocionados";
    }
    else if (Consulta.trim().indexOf("ciudad-") === -1) {
        let first = Consulta.indexOf("-");
        let last = Consulta.lastIndexOf("-");

        tipopublicacionparm = Consulta.substring(0, first);
        tipoprop = Consulta.substring(first + 1, last);
        consultastr = Consulta.substring(last + 1);
        idclienteparm = null;
        tipoconsulta = "direccion";
    }
    else {
        let first = Consulta.indexOf("-");
        let ciudad = Consulta.substring(first + 1, Consulta.length);
        tipopublicacionparm = "1"
        consultastr = ciudad;
        tipoprop = "1";
        idclienteparm = null;
        tipoconsulta = "ciudad";
    }

    const [tipopublicacion, settipopublicacion] = useState(tipopublicacionparm);


    const [direccion, setdireccion] = useState(consultastr);
    const [tipopropiedad, settipopropiedad] = useState(tipoprop);
    const [shouldFetchProperties, setShouldFetchProperties] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    const [rangodesdeprecio, setrangodesdeprecio] = useState('');
    const [rangohastaprecio, setrangohastaprecio] = useState('');
    const [rangodesdetamaño, setrangodesdetamaño] = useState('');
    const [rangohastatamaño, setrangohastatamaño] = useState('');

    const [searchPerformed, setSearchPerformed] = useState(false);

    const { user } = useContext(UserContext);
    let dt = user;

    const [search, setSearch] = useState({
        tipopublicacion: tipopublicacionparm,
        tipopropiedad: tipoprop,
        busqueda: consultastr.trim(),
        idcliente: idclienteparm,
        moneda: moneda,
        rangodesdeprecio: '',
        rangohastaprecio: '',
        rangodesdetamaño: '',
        rangohastatamaño: '',
        estacionamientos: estacionamientostext,
        idpropiedadfilter: idpropiedadfilter,
        baños: bañostext,
        habitaciones: habitacionestext,
        idusuario: user ? user.usuario.idusuario : null,
        comuna: '',
        Relacionada: false,
        nivelpromo: tipoconsulta === "Promocionados" || tipoconsulta === "PromocionadosBanner" ? 99 : null,
        origen: "BusquedaPropiedades"
    });

    //useEffect(() => {
    //    setIsMounted(true);
    //}, []);

    const handleImageClick = (event) => {
        // Prevent the default behavior (e.g., link navigation)
        event.preventDefault();
        event.stopPropagation(); // Stop event propagation

        if (toggleButtonRef.current) {
            const toggleButtonRect = toggleButtonRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            // Calculate the scroll position to center the toggle button element vertically
            const scrollTop = toggleButtonRect.top + window.scrollY - (viewportHeight / 2 - toggleButtonRect.height / 2);

            // Scroll the window to the calculated position
            window.scrollTo({
                top: scrollTop,
                behavior: 'smooth'
            });
        }
    };

    const fetchCercanosData = async (property) => {
        try {
            const query = `[out:json]; 
            (
                node(around:1000,${property.latitud},${property.longitud})["highway"="bus_stop"];
                node(around:1000,${property.latitud},${property.longitud})["railway"="station"];
                node(around:1000,${property.latitud},${property.longitud})["amenity"="school"];
            );
            out;`;

            const response = await axios.post('https://overpass-api.de/api/interpreter', query);

            if (response.data && response.data.elements) {
                const elements = response.data.elements;

                // Check for specific tags
                const hasSchool = elements.some(elem => elem.tags && elem.tags.amenity === 'school');
                const hasBusStop = elements.some(elem => elem.tags && elem.tags.highway === 'bus_stop');
                const hasTrainStation = elements.some(elem => elem.tags && elem.tags.railway === 'station');

                // Build the resulting text based on the logic
                let resultText = '';
                if (hasSchool) {
                    resultText += 'Cercano a Colegios';
                    if (hasBusStop) {
                        resultText += ', Paradas de Buses';
                        if (hasTrainStation) {
                            resultText += ', Metro';
                        }
                    } else if (hasTrainStation) {
                        resultText += ', Metro';
                    }
                } else if (hasBusStop) {
                    resultText += 'Cercano a Paradas de Buses';
                    if (hasTrainStation) {
                        resultText += ', Metro';
                    }
                } else if (hasTrainStation) {
                    resultText += 'Cercano a Metro';
                }

                return resultText;
            } else {
                throw new Error('No se encontraron datos de transporte.');
            }
        } catch (error) {
            console.error('Error al obtener datos de transporte: ', error);
            throw new Error('Ocurrió un error al obtener datos de transporte.');
        }
    };

    const GuardaHistorialBusqueda = useCallback((search) => {
        const url = '/api/usuario/GuardarHistorialBusquedas';
        const today = new Date();

        const historialBusquedas = {
            idusuario: dt ? dt.usuario.idusuario : null,
            idcliente: idclienteparm,
            RangoPrecioDesde: search.rangodesdeprecio,
            RangoPrecioHasta: search.rangohastaprecio,
            RangoTamañoDesde: search.rangodesdetamaño,
            RangoTamañoHasta: search.rangohastatamaño,
            tipopublicacion: search.tipopublicacion,
            numhabitaciones: search.habitaciones,
            numbaños: search.baños,
            numestacionamientos: search.estacionamientos,
            direccion: search.busqueda,
            tipopropiedad: search.tipopropiedad,
            fecha: today.toISOString() // Format the date as YYYY-MM-DD
        };

        axios
            .post(url, historialBusquedas)
            .then((response) => {
                // Handle success response here
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    console.error('Request failed with status:', error.response.status);
                    console.error('Response data:', error.response.data);

                    // Retrieve and log the validation errors
                    const validationErrors = error.response.data.errors;
                    console.error('Validation errors:', validationErrors);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an error
                    console.error('Error setting up the request:', error.message);
                }
                console.error('Error saving search history:', error);
            });
    
    }, [dt, idclienteparm]);

    const fetchProperties = useCallback(async (pageNumber, sortOption, propertiesPerPage) => {
        try {
            //if (tipoconsulta === "Cliente" || tipoconsulta === "ciudad" || tipoconsulta === "Promocionados") {
            if (tipoconsulta === "ciudad" || tipoconsulta === "Promocionados") {
                setShowContent(false);
                setShowRecomendaciones(false);
            }
            else {
                setShowRecomendaciones(true);
            }
            if (tipoconsulta === "Cliente") setShowRecomendaciones(false);

            setLoading(true);
            const startIndex = (pageNumber - 1) * propertiesPerPage;
            const endIndex = startIndex + propertiesPerPage;
            setProperties([]);
            const response = await axios.post(`/api/propiedades/ConsultaPropsEx?startIndex=${startIndex}&endIndex=${endIndex}&sort=${sortOption}&tipo=${tipoconsulta}`, search);

            const { properties, totalCount } = response.data;

            // Fetch additional data for each property
            const propertiesWithCercanosData = await Promise.all(
                properties.map(async (property) => {
                    try {
                        const cercanosData = await fetchCercanosData(property.property);
                        return { ...property, cercanosData };
                    } catch (error) {
                        console.error('Error fetching cercanos data:', error);
                        return property;
                    }
                })
            );

            setTotalCount(totalCount);
            setPropertiesToDisplay(propertiesWithCercanosData);
            setProperties(propertiesWithCercanosData); // Set the properties with cercanosData
            setLoading(false);
            setFetchComplete(true);

            // Calculate total pages based on the total number of properties
            const calculatedTotalPages = Math.ceil(totalCount / propertiesPerPage);
            setTotalPages(calculatedTotalPages);

            //if (properties.length > 0) {
            //    if (properties[0].property.ciudad.toLowerCase() === search.Busqueda.toLowerCase() && showRecomendaciones) {
            //        setShowRecomendaciones(false);
            //    }
            //}

            if (properties.length > 0 && dt) {
                GuardaHistorialBusqueda(search);
            }

            if (properties.length > 0) {
                // Scroll to the first property after updating the state
                const firstPropertyElement = document.querySelector('.single-feature');
                if (firstPropertyElement) {
                    firstPropertyElement.scrollIntoView({ behavior: 'smooth' });
                }
            }

            if (properties.length === 0) {
                Swal.fire('Aviso', '¡No se encontraron propiedades segun los criterios de busqueda, cambie la comuna, direccion u otros parametros y busque nuevamente!', 'info');
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, [search, tipoconsulta, GuardaHistorialBusqueda, dt, showRecomendaciones]);




    useEffect(() => {
        if (shouldFetchProperties) {
            fetchProperties(currentPage, sortOption, propertiesPerPage);
            setShouldFetchProperties(false); // Reset flag after fetch
        }
        // Scroll to the top when the page is loaded
        //window.scrollTo(0, 0);
    }, [fetchProperties, currentPage, sortOption, shouldFetchProperties, propertiesPerPage]); // Include shouldFetchProperties in the dependency array

    const handleSortChange = useCallback((event) => {
        const selectedOption = event.target.value;
        setSortOption(selectedOption);
        setShouldFetchProperties(true);
        setCurrentPage(1);
    }, []);

    const handlerangodesdeprecioChange = (event) => {
        let { value } = event.target;

        // Remove non-numeric characters except the decimal point
        value = value.replace(/[^\d.]/g, "");
        // Format for CLP (PESOS) - add thousands separator
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        setrangodesdeprecio(value);
    };

    const handlerangohastaprecioChange = (event) => {
        let { value } = event.target;

        // Remove non-numeric characters except the decimal point
        value = value.replace(/[^\d.]/g, "");
        // Format for CLP (PESOS) - add thousands separator
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        setrangohastaprecio(value);
    };

    const handlerangodesdetamañoChange = (event) => {
        let { value } = event.target;

        // Remove non-numeric characters except the decimal point
        value = value.replace(/[^\d.]/g, "");
        // Format for CLP (PESOS) - add thousands separator
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        setrangodesdetamaño(value);
    };

    const handlerangohastatamañoChange = (event) => {
        let { value } = event.target;

        // Remove non-numeric characters except the decimal point
        value = value.replace(/[^\d.]/g, "");
        // Format for CLP (PESOS) - add thousands separator
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        setrangohastatamaño(value);
    };

    

    const handleBuscarpropiedades = (event) => {
        event.preventDefault();
        setProperties([]);
        setShowMap(false);
        setCurrentPage(1);

        const updatedSearch = {
            ...search,
            tipopublicacion: tipopublicacion,
            tipopropiedad: tipopropiedad,
            busqueda: direccion.trim(),
            moneda: moneda,
            rangodesdeprecio: rangodesdeprecio.split(',').join(''),
            rangohastaprecio: rangohastaprecio.split(',').join(''),
            rangodesdetamaño: rangodesdetamaño.split(',').join(''),
            rangohastatamaño: rangohastatamaño.split('').join(''),
            estacionamientos: estacionamientostext,
            idpropiedadfilter: idpropiedadfilter,
            baños: bañostext,
            habitaciones: habitacionestext,
            Relacionada: false,
            nivelpromo: tipoconsulta === "Promocionados" ? 99 : null
        };

        setSearch(updatedSearch);
        setShouldFetchProperties(true);
        setSearchPerformed(true);
    };


    const handletipopublicacionChange = (value) => {
        settipopublicacion(value);
    };

    const handledireccionChange = (event) => {
        setdireccion(event.target.value);
    };

    const handleidpropiedadfilterChange = (event) => {
        setidpropiedadfilter(event.target.value);

        setdireccion("");
        setbaños('1');
        setbañosText('Todos');
        setestacionamientos('1');
        setestacionamientosText('Todos');
        sethabitaciones('1');
        sethabitacionesText('Todas');
        setrangodesdeprecio('');
        setrangohastaprecio('');
        setrangodesdetamaño('');
        setrangohastatamaño('');
    };

    const handletipopropiedadChange = (event) => {
        settipopropiedad(event.target.value);
    };

    const handlehabitacionesChange = (event) => {
        const selectedOptionText = event.target.options[event.target.selectedIndex].text;
        sethabitaciones(event.target.value);
        sethabitacionesText(selectedOptionText);
    };

    const handlebañosChange = (event) => {
        const selectedOptionText = event.target.options[event.target.selectedIndex].text;
        setbaños(event.target.value);
        setbañosText(selectedOptionText);
    };

    const handleestacionamientosChange = (event) => {
        const selectedOptionText = event.target.options[event.target.selectedIndex].text;
        setestacionamientos(event.target.value);
        setestacionamientosText(selectedOptionText);
    };

    const buttonStyleActive = {
        backgroundColor: '#f35b2c', // Replace with your desired active color
        borderColor: '#ff0000', // Replace with your desired active color
        color: '#ffffff', // Replace with your desired active text color
    };

    const buttonStyleInactive = {
        backgroundColor: '#888888', // Replace with your desired inactive color
        borderColor: '#888888', // Replace with your desired inactive color
        color: '#ffffff', // Replace with your desired inactive text color
    };


    const handleSearchClick = async () => {
        fetchProperties(1,sortOption,propertiesPerPage);
        //setProperties(properties); // Update properties state with fetched data
        if (properties.length > 0) setShowMap(true); // Set showMap to true to display the map
        setShowContent(false);
    };

    const handleMouseOver = (property) => {
        setHoveredProperty(property);
        setShowCard(true);
    };

    const handleMouseOut = () => {
        setHoveredProperty(null);
        setShowCard(false);
    };

    // Handler for pagination buttons
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);

        // Call fetchProperties with the new page number
        fetchProperties(pageNumber, sortOption, propertiesPerPage);
    };



    const handlePropertiesPerPageChange = (event) => {
        const perPage = parseInt(event.target.value);
        setPropertiesPerPage(perPage);
        setCurrentPage(1); // Reset current page when changing properties per page

        // Update total pages based on the new properties per page
        const calculatedTotalPages = Math.ceil(totalCount / perPage);
        setTotalPages(calculatedTotalPages);

        // Use the updated propertiesPerPage value in the callback
        fetchProperties(1, sortOption, perPage);
    };


    const handleToggleContent = () => {
        setShowContent((prevShowContent) => !prevShowContent);
    };

    const handlePropertySelection = (property) => {
        if (selectedProperties.includes(property)) {
            setSelectedProperties(selectedProperties.filter((p) => p !== property));
        } else {
            setSelectedProperties([...selectedProperties, property]);
        }
    };

    const handleCompareClick = () => {
        // Extracts the property IDs from the selectedProperties array
        const selectedPropertyIds = selectedProperties.map((property) => property.idpropiedad);

        // Verifies if there are exactly 2 selected properties
        if (selectedPropertyIds.length !== 2) {
            Swal.fire('Aviso', 'Debe seleccionar 2 propiedades para comparar, para seleccionar una propiedad debe marcar una casilla cuadrada que esta bajo la imagen al lado derecho.', 'info');
            return; // Stop further execution of the function
        }

        // Constructs a URL using the extracted property IDs
        const url = `/compararpropiedades/${selectedPropertyIds[0]}/${selectedPropertyIds[1]}`;

        // Navigates to the constructed URL, replacing the current page in the browser history
        navigate(url, { replace: true });
    };


    const toggleSortDropdown = () => {
        setSortDropdownOpen(!sortDropdownOpen);
    };

    const toggleViewDropdown = () => {
        setViewDropdownOpen(!viewDropdownOpen);
    };

    const togglePerPageDropdown = () => {
        setPerPageDropdownOpen(!perPageDropdownOpen);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const newPage = currentPage - 1;
            setCurrentPage(newPage);
            fetchProperties(newPage, sortOption, propertiesPerPage);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            const newPage = currentPage + 1;
            setCurrentPage(newPage);
            fetchProperties(newPage, sortOption, propertiesPerPage);
        }
    };


    return (
        <div className="search-page-wrap pd-top-100 pd-bottom-70">
            {loading && (
                <div className="preloader" id="preloader">
                    <div className="preloader-inner">
                        <div className="spinner">
                            <div className="dot1"></div>
                            <div className="dot2"></div>
                        </div>
                    </div>
                </div>
            )}
            <div className="search-container">
                <div className="container">
                    <Toolbar
                        handleBuscarpropiedades={handleBuscarpropiedades}
                        handleBuscarMapa={handleSearchClick}
                        handleSortChange={handleSortChange}
                        viewDropdownOpen={viewDropdownOpen}
                        sortDropdownOpen={sortDropdownOpen}
                        toggleViewDropdown={toggleViewDropdown}
                        settipoVista={settipoVista}
                        perPageDropdownOpen={perPageDropdownOpen}
                        toggleSortDropdown={toggleSortDropdown}
                        togglePerPageDropdown={togglePerPageDropdown}
                        handlePropertiesPerPageChange={handlePropertiesPerPageChange}
                        handleCompareClick={handleCompareClick}
                        searchPerformed={searchPerformed}
                    />
                    <div className="row">
                        <div className="col-xl-1">
                            <div className={`toggle-button`} onClick={handleToggleContent} ref={toggleButtonRef}>
                                {showContent ? <i className="fa fa-angle-left" /> : <i className="fa fa-angle-right" />}
                            </div>
                        </div>
                        {showContent && (
                            <div className={`col-xl-4 col-lg-4 sitebar`}>
                                <h6 className="filter-title mb-1">
                                    <img
                                        className="mr-3"
                                        src={"assets/img/icons/18.png"}
                                        alt="img"
                                        onClick={(event) => handleImageClick(event)}
                                    />
                                    Filtros
                                </h6>
                                <div className="widget-sidebar-search-wrap">
                                    <form className="widget-sidebar-search" onSubmit={handleBuscarpropiedades}>
                                        <br />
                                        <div className="title">Tipo Publicación</div>
                                        <div className="widget-sidebar-item-wrap btn-area">
                                            <Button
                                                style={tipopublicacion === 'Comprar' ? buttonStyleActive : buttonStyleInactive}
                                                onClick={() => handletipopublicacionChange('Comprar')}
                                                className="mr-5"
                                            >
                                                Compra
                                            </Button>
                                            <Button
                                                style={tipopublicacion === 'Arrendar' ? buttonStyleActive : buttonStyleInactive}
                                                onClick={() => handletipopublicacionChange('Arrendar')}
                                            >
                                                Arriendo
                                            </Button>
                                        </div>
                                        <div className="widget-sidebar-item-wrap rld-single-input left-icon">
                                            <input
                                                type="text"
                                                placeholder="Ingresa direccion o ciudad o comuna"
                                                value={direccion}
                                                onChange={handledireccionChange}
                                            />
                                        </div>
                                        <div className="widget-sidebar-item-wrap rld-single-select">
                                            <select
                                                className="select single-select"
                                                value={tipopropiedad}
                                                onChange={handletipopropiedadChange}
                                            >
                                                <option value={1}>Todas las propiedades</option>
                                                <option value={2}>Casa</option>
                                                <option value={3}>Departamento</option>
                                                <option value={4}>Galpon</option>
                                                <option value={5}>Local Comercial</option>
                                                <option value={6}>Terreno</option>
                                                <option value={7}>Oficina</option>
                                                <option value={8}>Parcela</option>
                                                <option value={9}>Bodega</option>
                                                <option value={10}>Derecho Llave</option>
                                                <option value={11}>Estacionamiento</option>
                                            </select>
                                        </div>
                                        <div className="widget-sidebar-item-wrap">
                                            <div className="title">Rango Precios</div>
                                            <select
                                                className="select single-select"
                                                value={moneda}
                                                onChange={(event) => setMoneda(event.target.value)}
                                            >
                                                <option value={"TODAS"}>TODAS</option>
                                                <option value={"CLP"}>PESOS</option>
                                                <option value={"UF"}>UF</option>
                                            </select>
                                            <div className="widget-sidebar-item-wrap rld-single-input">
                                                <input
                                                    type="text"
                                                    placeholder="Rango Desde"
                                                    value={rangodesdeprecio}
                                                    onChange={handlerangodesdeprecioChange}
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="Rango Hasta"
                                                    value={rangohastaprecio}
                                                    onChange={handlerangohastaprecioChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="widget-sidebar-item-wrap">
                                            <div className="title">Rango Tamaño Mt2</div>
                                            <div className="widget-sidebar-item-wrap rld-single-input">
                                                <input
                                                    type="text"
                                                    placeholder="Rango Desde"
                                                    value={rangodesdetamaño}
                                                    onChange={handlerangodesdetamañoChange}
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="Rango Hasta"
                                                    value={rangohastatamaño}
                                                    onChange={handlerangohastatamañoChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                                            <div className="title d-inline-block float-left mb-0 pt-2">Habs</div>
                                            <div className="rld-single-select d-inline-block float-right">
                                                <select className="select single-select" value={habitaciones} onChange={handlehabitacionesChange}>
                                                    <option value={1}>Todas</option>
                                                    <option value={2}>1-2</option>
                                                    <option value={3}>3-4</option>
                                                    <option value={4}>5-6</option>
                                                    <option value={5}>Minimo 7</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                                            <div className="title d-inline-block float-left mb-0 pt-2">baños</div>
                                            <div className="rld-single-select d-inline-block float-right">
                                                <select className="select single-select" value={baños} onChange={handlebañosChange}>
                                                    <option value={1}>Todos</option>
                                                    <option value={2}>1-2</option>
                                                    <option value={3}>3-4</option>
                                                    <option value={4}>5-6</option>
                                                    <option value={5}>Minimo 7</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="widget-sidebar-item-wrap rld-single-select-wrap mb-4">
                                            <div className="title d-inline-block float-left mb-0 pt-2">Estac.</div>
                                            <div className="rld-single-select d-inline-block float-right">
                                                <select
                                                    className="select single-select"
                                                    value={estacionamientos}
                                                    onChange={handleestacionamientosChange}
                                                >
                                                    <option value={1}>Todos</option>
                                                    <option value={2}>1-2</option>
                                                    <option value={3}>3-4</option>
                                                    <option value={4}>5-6</option>
                                                    <option value={5}>Minimo 7</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="widget-sidebar-item-wrap">
                                            <div className="title">Id Propiedad</div>
                                            <div className="widget-sidebar-item-wrap rld-single-input">
                                                <input
                                                    type="text"
                                                    required="false"
                                                    placeholder="Ingrese solo si conoce el Id unico"
                                                    value={idpropiedadfilter}
                                                    onChange={handleidpropiedadfilterChange}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="my-4"> {/* Adding margin top of 4 */}
                                    <div className="text-center">
                                        <button
                                            className={`btn btn-yellow btn-sm mb-4 mr-1 ${!searchPerformed ? 'highlight' : ''}`}
                                            style={{ fontSize: '0.9rem', minWidth: '7rem' }}
                                            onClick={handleBuscarpropiedades}
                                        >
                                            ¡Buscar Nuevamente!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={`col-xl-${showContent ? '7' : '9'} col-lg-${showContent ? '8' : '10'}`}>
                            {showMap ? (
                                <div style={{ height: '800px', width: '800px' }}>
                                    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                                        <div style={{ height: '100%' }}>
                                            {properties.length > 0 &&
                                                <MyMapComponent
                                                    properties={properties}
                                                    handleMouseOver={handleMouseOver}
                                                    handleMouseOut={handleMouseOut}
                                                    hoveredProperty={hoveredProperty}
                                                    showCard={showCard}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <h6 className="filter-title mt-3 mb-lg-0">
                                                    {totalCount} {totalCount === 1 ? 'propiedad' : 'Props'}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!showMap && tipovista === '2' && (
                                <div className="row justify-content-center">
                                    {propertiesToDisplay.map((property, i) => (
                                        <div key={i} className="col-xl-5 col-sm-6">
                                            <div className="single-feature">
                                                <Thumb imageData={property.base64Data} height={200} hasPromo={property.property.nivelpromo} idpropiedad={property.property.idpropiedad} />
                                                <div className="details">
                                                    <input
                                                        type="checkbox"
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            position: 'absolute',
                                                            top: '30px',
                                                            right: '10px',
                                                        }}
                                                        checked={selectedProperties.includes(property.property)}
                                                        onChange={() => handlePropertySelection(property.property)}
                                                    />
                                                    {Details(property, dt)}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {!showMap && tipovista === '1' && propertiesToDisplay.map((property, i) => (
                                <div key={i} className="single-feature style-three">
                                    <Thumb imageData={property.base64Data} height={380} hasPromo={property.property.nivelpromo} idpropiedad={property.property.idpropiedad} />
                                    <div className="details">
                                        <div className="details-wrap">
                                            <input
                                                type="checkbox"
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    position: 'absolute',
                                                    top: '30px',
                                                    right: '10px',
                                                }}
                                                onChange={() => handlePropertySelection(property.property)}
                                            />
                                            {Details(property, dt)}
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {properties.length > 0 && (
                                <div className="pagination-wrap text-center">
                                    <button className="btn btn-yellow" onClick={handlePreviousPage} disabled={currentPage === 1}>Anterior</button>
                                    {pageNumbers.map((number) => (
                                        <button
                                            className={`btn btn-yellow ${number === currentPage ? 'highlighted-button' : ''}`}
                                            key={number}
                                            onClick={() => handlePageChange(number)}
                                        >
                                            {number}
                                        </button>
                                    ))}

                                    <button className="btn btn-yellow" onClick={handleNextPage} disabled={currentPage === totalPages}>Siguiente</button>
                                </div>
                            )}
                           
                        </div>
                    </div>
                </div>
            </div>
            {propertiesToDisplay.length > 0 && fetchComplete && showRecomendaciones && (
                <PropiedadesRecomendadas
                    key={JSON.stringify(search)}
                    searchparm={search}
                    propertyzero={propertiesToDisplay[0].property}
                 />
            )}
        </div>
    );


}


export default BusquedaPropiedades