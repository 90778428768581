import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Swal from 'sweetalert2';
import { UserContext } from '../../context/UserProvider';

const ClientesGrid = () => {
    const [clients, setClients] = useState([]);
    const [descripcion, setdescripcion] = useState('');
    const [tipo, settipo] = useState('');
    const [email, setEmail] = useState('');
    const [selectedClient, setSelectedClient] = useState(null);
    const [iconofile, seticonofile] = useState(null);
    const [urlconvecta, seturlconvecta] = useState('');
    const [ejecutalunes, setEjecutaLunes] = useState(true);
    const [ejecutamartes, setEjecutaMartes] = useState(true);
    const [ejecutamiercoles, setEjecutaMiercoles] = useState(true);
    const [ejecutajueves, setEjecutaJueves] = useState(true);
    const [ejecutaviernes, setEjecutaViernes] = useState(true);
    const [ejecutasabado, setEjecutaSabado] = useState(true);
    const [ejecutadomingo, setEjecutaDomingo] = useState(true);
    const [urltoppropiedades, seturltoppropiedades] = useState('');
    const [descuento, setDescuento] = useState('');
    const [tiendadeshabilitada, setTiendaDeshabilitada] = useState(false);
    const [mapaprod_agencyid, setMapaprodagencyid] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalClients, setTotalClients] = useState(0);
    const [clientsPerPage] = useState(20);
    const [totalPages, setTotalPages] = useState(1);
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1); // Create an array of page 

    const { user } = useContext(UserContext);

    const [fileInputKey, setFileInputKey] = useState(Date.now());

    useEffect(() => {
        fetchclientes(currentPage);
    }, [currentPage]);

    const fetchclientes = async (page) => {
        try {
            if (page === undefined) page = 1;

            const response = await axios.get(`/api/clientes/getclientes?page=${page}&limit=${clientsPerPage}`);
            setClients(response.data.clients); // Assuming the response contains the clients array
            setTotalClients(response.data.total); // Assuming the response contains the total count of clients
            setTotalPages(Math.ceil(response.data.total / clientsPerPage));
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const handleAdd = async () => {
        try {
            const formData = new FormData();
            formData.append('descripcion', descripcion);
            formData.append('tipo', tipo);
            formData.append('iconofile', iconofile);
            formData.append('emailresponsable', email);
            formData.append('urlconvecta', urlconvecta);
            formData.append('ejecutalunes', ejecutalunes ? 1 : 0);
            formData.append('ejecutamartes', ejecutamartes ? 1 : 0);
            formData.append('ejecutamiercoles', ejecutamiercoles ? 1 : 0);
            formData.append('ejecutajueves', ejecutajueves ? 1 : 0);
            formData.append('ejecutaviernes', ejecutaviernes ? 1 : 0);
            formData.append('ejecutasabado', ejecutasabado ? 1 : 0);
            formData.append('ejecutadomingo', ejecutadomingo ? 1 : 0);
            formData.append('urltoppropiedades', urltoppropiedades);
            formData.append('descuento', descuento);
            formData.append('tiendadeshabilitada', tiendadeshabilitada ? 1 : 0);
            formData.append('mapaprod_agencyid', mapaprod_agencyid);

            await axios.post('/api/clientes/AgregaCliente', formData);
            resetForm();
            fetchclientes(currentPage);
        } catch (error) {
            console.error('Error adding client:', error);
        }
    };

    const handleUpdate = async () => {
        if (!selectedClient) return;

        try {
            const formData = new FormData();
            formData.append('idcliente', selectedClient.idcliente);
            formData.append('descripcion', descripcion);
            formData.append('tipo', tipo);
            formData.append('iconofile', iconofile);
            formData.append('emailresponsable', email);
            formData.append('urlconvecta', urlconvecta);
            formData.append('ejecutalunes', ejecutalunes ? 1 : 0);
            formData.append('ejecutamartes', ejecutamartes ? 1 : 0);
            formData.append('ejecutamiercoles', ejecutamiercoles ? 1 : 0);
            formData.append('ejecutajueves', ejecutajueves ? 1 : 0);
            formData.append('ejecutaviernes', ejecutaviernes ? 1 : 0);
            formData.append('ejecutasabado', ejecutasabado ? 1 : 0);
            formData.append('ejecutadomingo', ejecutadomingo ? 1 : 0);
            formData.append('urltoppropiedades', urltoppropiedades);
            formData.append('descuento', descuento);
            formData.append('tiendadeshabilitada', tiendadeshabilitada ? 1 : 0);
            formData.append('mapaprod_agencyid', mapaprod_agencyid);

            await axios.put('/api/clientes/updateCliente', formData);
            resetForm();
            fetchclientes();
        } catch (error) {
            console.error('Error updating client:', error);
        }
    };

    const handleDelete = async (clientId) => {
        try {
            const confirmed = await Swal.fire({
                title: 'Confirmación',
                text: '¿Está seguro que desea eliminar el Cliente?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, Eliminarlo',
                cancelButtonText: 'Cancelar',
            });

            if (confirmed.isConfirmed) {
                const response = await axios.post(`/api/clientes/ExistenpropiedadesoUsuarios/${clientId}`);

                if (response.status === 200) {
                    Swal.fire('Aviso', 'No se puede eliminar el cliente porque tiene propiedades o usuarios asociados', 'info');
                    return;
                }

                await axios.delete(`/api/clientes/deleteCliente/${clientId}`);
                fetchclientes();
                resetForm();
            }
        } catch (error) {
            console.error('Error deleting client:', error);
        }
    };

    const resetForm = () => {
        setdescripcion('');
        settipo('');
        seticonofile(null);
        setEmail('');
        seturlconvecta('');
        setEjecutaLunes(true);
        setEjecutaMartes(true);
        setEjecutaMiercoles(true);
        setEjecutaJueves(true);
        setEjecutaViernes(true);
        setEjecutaSabado(true);
        setEjecutaDomingo(true);
        setSelectedClient(null);
        setFileInputKey(Date.now());
        seturltoppropiedades('');
        setDescuento('');
        setTiendaDeshabilitada(false);
        setMapaprodagencyid('');
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        seticonofile(file);
    };

    const handleDayOfWeekChange = (day) => {
        switch (day) {
            case 'lunes':
                setEjecutaLunes(!ejecutalunes);
                break;
            case 'martes':
                setEjecutaMartes(!ejecutamartes);
                break;
            case 'miercoles':
                setEjecutaMiercoles(!ejecutamiercoles);
                break;
            case 'jueves':
                setEjecutaJueves(!ejecutajueves);
                break;
            case 'viernes':
                setEjecutaViernes(!ejecutaviernes);
                break;
            case 'sabado':
                setEjecutaSabado(!ejecutasabado);
                break;
            case 'domingo':
                setEjecutaDomingo(!ejecutadomingo);
                break;
            default:
                break;
        }
    };

    const gettipoText = (value) => {
        switch (value) {
            case 1:
                return 'Corredora de propiedades';
            case 2:
                return 'Inmobiliaria';
            default:
                return '';
        }
    };

    const handleEdit = (client) => {
        console.log('tiendadeshabilitada from database:', client.tiendadeshabilitada);
        setSelectedClient(client);
        setdescripcion(client.descripcion);
        settipo(client.tipo);
        setEmail(client.emailresponsable);
        seturlconvecta(client.urlconvecta);
        setEjecutaLunes(client.ejecutalunes);
        setEjecutaMartes(client.ejecutamartes);
        setEjecutaMiercoles(client.ejecutamiercoles);
        setEjecutaJueves(client.ejecutajueves);
        setEjecutaViernes(client.ejecutaviernes);
        setEjecutaSabado(client.ejecutasabado);
        setEjecutaDomingo(client.ejecutadomingo);
        seticonofile(null);
        seturltoppropiedades(client.urltoppropiedades);
        setDescuento(client.descuento);
        setTiendaDeshabilitada(client.tiendadeshabilitada === 1);
        setMapaprodagencyid(client.mapaprod_agencyid);
    };

    const handleCancel = () => {
        resetForm();
    };

    const truncateUrl = (url, maxLength = 80) => {
        if (!url) return 'N/A';
        return url.length > maxLength ? `${url.substring(0, maxLength - 3)}...` : url;
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalClients / clientsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handler for pagination buttons
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);

        // Call fetchProperties with the new page number
        fetchclientes(pageNumber);
    };

    return (
        <div className="container" style={{ width: '100%', maxWidth: '100%' }}>
            <div className="d-flex flex-column align-items-center mt-5">
                <h2>Detalle de clientes</h2>
                <div className="w-50 border p-4 rounded" style={{ width: '100%', margin: '0 auto' }}>
                    <Form onSubmit={(e) => { e.preventDefault(); selectedClient ? handleUpdate() : handleAdd(); }}>
                        <FormGroup>
                            <Label for="descripcion">Descripción Cliente</Label>
                            <Input
                                type="text"
                                id="descripcion"
                                value={descripcion}
                                onChange={(e) => setdescripcion(e.target.value)}
                                placeholder="Descripción"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="tipo">tipo Cliente</Label>
                            <Input
                                type="select"
                                id="tipo"
                                value={tipo}
                                onChange={(e) => settipo(e.target.value)}
                                required
                            >
                                <option value="">Seleccione un tipo</option>
                                <option value="1">Corredora de propiedades</option>
                                <option value="2">Inmobiliaria</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email Responsable</Label>
                            <Input
                                type="text"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email Responsable"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="urlconvecta">URL Convecta</Label>
                            <Input
                                type="text"
                                id="urlconvecta"
                                value={urlconvecta}
                                onChange={(e) => seturlconvecta(e.target.value)}
                                placeholder="URL Convecta"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="urltoppropiedades">URL Top Propiedades</Label>
                            <Input
                                type="text"
                                id="urltoppropiedades"
                                value={urltoppropiedades}
                                onChange={(e) => seturltoppropiedades(e.target.value)}
                                placeholder="URL Top Propiedades"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="mapaprod_agencyid">Mapaprod AgencyId</Label>
                            <Input
                                type="text"
                                id="mapaprod_agencyid"
                                value={mapaprod_agencyid}
                                onChange={(e) => setMapaprodagencyid(e.target.value)}
                                placeholder="Mapaprod AgencyId"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="descuento">Descuento</Label>
                            <Input
                                type="text"
                                id="descuento"
                                value={descuento}
                                onChange={(e) => setDescuento(e.target.value)}
                                placeholder="Descuento"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="tiendadeshabilitada">Tienda Deshabilitada</Label>
                            <Input
                                style={{ marginLeft: '20px' }}
                                type="checkbox"
                                id="tiendadeshabilitada"
                                value={tiendadeshabilitada}
                                checked={tiendadeshabilitada}
                                onChange={(e) => setTiendaDeshabilitada(e.target.checked)}
                                placeholder="Tienda Deshabilitada"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Seleccionar días de ejecución integración:</Label>
                            {['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'].map((day) => (
                                <FormGroup check key={day}>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            checked={eval(`ejecuta${day.charAt(0) + day.slice(1)}`)}
                                            onChange={() => handleDayOfWeekChange(day)}
                                        />
                                        {day.charAt(0).toUpperCase() + day.slice(1)}
                                    </Label>
                                </FormGroup>
                            ))}
                        </FormGroup>
                        <FormGroup style={{ marginBottom: '20px' }}>
                            <Label for="icono">icono</Label>
                            <Input type="file" id="icono" key={fileInputKey} accept="image/*" onChange={handleFileChange} required />
                        </FormGroup>
                        <FormGroup className="text-center">
                            <Button type="submit" className="btn btn-yellow">
                                {selectedClient ? 'Actualizar' : 'Agregar'}
                            </Button>
                            {selectedClient && (
                                <Button type="button" onClick={handleCancel} className="ml-2 btn btn-secondary">
                                    Cancelar
                                </Button>
                            )}
                        </FormGroup>
                    </Form>
                </div>
                <ul className="mt-4 w-50 list-group">
                    {clients.map((client) => (
                        <li key={client.idcliente} className="list-group-item d-flex flex-column align-items-start mb-3">
                            <div className="client-info">
                                <span className="font-weight-bold">Descripción:</span> {client.descripcion}<br />
                                <span className="font-weight-bold">tipo:</span> {gettipoText(client.tipo)}<br />
                                <span className="font-weight-bold">Email Responsable:</span> {client.emailresponsable}<br />
                                <span className="font-weight-bold">URL Convecta:</span> {truncateUrl(client.urlconvecta)}<br />
                                <span className="font-weight-bold">URL Top Prop:</span> {truncateUrl(client.urltoppropiedades)}<br />
                                <span className="font-weight-bold">Mapaprod AgencyId:</span> {truncateUrl(client.mapaprod_agencyid)}<br />
                                <span className="font-weight-bold">Descuento:</span> {truncateUrl(client.descuento)}<br />
                                <span className="font-weight-bold">Tienda Deshabilitada:</span> {truncateUrl(client.tiendadeshabilitada)}
                            </div>
                            <div className="d-flex align-items-start">
                                {client.icono && (
                                    <div className="client-image mr-3">
                                        <img
                                            src={`data:image/jpeg;base64,${client.icono}`}
                                            alt="icono"
                                            className="img-thumbnail"
                                            style={{ maxWidth: '150px', maxHeight: '150px' }}
                                        />
                                    </div>
                                )}
                                <div className="client-actions">
                                    <button onClick={() => handleEdit(client)} className="btn btn-yellow mr-2">
                                        Editar
                                    </button>
                                    <button onClick={() => handleDelete(client.idcliente)} className="btn btn-lightblue">
                                        Eliminar
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="pagination-wrap text-center">
                    <button className="btn btn-yellow" onClick={handlePreviousPage} disabled={currentPage === 1}>Anterior</button>
                    {pageNumbers.map((number) => (
                        <button
                            className={`btn btn-yellow ${number === currentPage ? 'highlighted-button' : ''}`}
                            key={number}
                            onClick={() => handlePageChange(number)}
                        >
                            {number}
                        </button>
                    ))}

                    <button className="btn btn-yellow" onClick={handleNextPage} disabled={currentPage === totalPages}>Siguiente</button>
                </div>
            </div>
        </div>
    );
};

export default ClientesGrid;
