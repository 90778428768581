import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from "../../context/UserProvider"
import Swal from 'sweetalert2'
import { Input, Label } from 'reactstrap';
import territorio from '../../data/territoriochile.json';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FormatoNumero } from '../global-components/globalfunctions';

function Thumb({ imageData, height, hasPromo }) {
    return (
        <div className="thumb">
            <div className="thumb-image" style={{ position: 'relative' }}>
                {hasPromo && (
                    <div className="promo-icon" style={{ position: 'absolute', top: 15, left: 15 }}>
                        <img src={'/assets/img/icons/promo-icon-9.png'} alt="Promo" style={{ width: '40px', height: '40px' }} />
                    </div>
                )}
                <img
                    src={`data:image/jpeg;base64,${imageData}`}
                    alt="img"
                    style={{ width: '100%', height: `${height}px` }}
                />
            </div>
        </div>
    );
}


const Mispropiedades = () => {
    const { user } = useContext(UserContext);
    const dt = user;
    const [properties, setProperties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [propertiesPerPage, setPropertiesPerPage] = useState(20);
    const [tipovista, settipoVista] = useState('2');
    const [comuna, setComuna] = useState('');
    const [palabraclave, setPalabraClave] = useState('');
    const [region, setRegion] = useState('');
    const [regionOptions, setRegionOptions] = useState([]);
    const [comunaOptions, setComunaOptions] = useState([]);
    const [sortOption, setSortOption] = useState('1'); // State for the selected sort option
    const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
    const [viewDropdownOpen, setViewDropdownOpen] = useState(false);
    const [perPageDropdownOpen, setPerPageDropdownOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1); // Create an array of page numbers
    const [verTodas, setVerTodas] = useState(false); // State for the checkbox
    const [verTodasPromo, setVerTodasPromo] = useState(false); // State for the checkbox
    const [ejecutivo, setEjecutivo] = useState(''); // State for the selected ejecutivo
    const [ejecutivos, setEjecutivos] = useState([]); // List of ejecutivos
    const [esresponsable, setEsResponsable] = useState(false); // List of ejecutivos

    const navigate = useNavigate(); // Add useNavigate here

    const fetchProperties = useCallback(async (pageNumber, sortOption, propertiesPerPage, palabraclave, verTodasvalue, verTodasPromovalue, Ejecutivovalue) => {
        setIsLoading(true);

        const startIndex = (pageNumber - 1) * propertiesPerPage;
        const endIndex = startIndex + propertiesPerPage;

        const search = {
            tipopublicacion: "",
            tipopropiedad: "",
            Busqueda: palabraclave,
            moneda: "",
            rangodesdeprecio: "",
            rangohastaprecio: "",
            rangodesdetamaño: "",
            rangohastatamaño: "",
            estacionamientos: "",
            baños: "",
            habitaciones: "",
            idusuario: dt.usuario.idusuario,
            comuna: comuna
        };

        if (verTodasvalue && user.idcliente) {
            search.idcliente = user.idcliente.toString();
            search.idusuario = null;
            if (Ejecutivovalue) {
                const UsuarioResponse = await axios.get(`/api/usuario/ObtieneUsuarioPorEmail/${Ejecutivovalue}`);
                search.idusuario = UsuarioResponse.data.idusuario;
            }
        }

        if (verTodasPromovalue) {
            search.nivelpromo = "99";
        }
        

        axios
            .post(`/api/propiedades/ConsultaPropsEx?startIndex=${startIndex}&endIndex=${endIndex}&sort=${sortOption}`, search)
            .then((response) => {
                const { properties, totalCount } = response.data;
                setTotalCount(totalCount);

                setIsLoading(false);

                const calculatedTotalPages = Math.ceil(totalCount / propertiesPerPage);
                setTotalPages(calculatedTotalPages);

                // Now properties already include additional data like images and user information,
                // so you can directly set them in your state.
                setProperties(properties);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }, [comuna]);


    useEffect(() => {
        if (dt)
            fetchProperties(currentPage, sortOption, propertiesPerPage, palabraclave, verTodas, ejecutivo);
            window.scrollTo(0, 0);
    }, [dt, propertiesPerPage, sortOption]);

    const territorioData = useMemo(() => territorio || [], []);

    useEffect(() => {
        // Initialize region options
        const regionOptions = territorioData.map((region) => region.nombre).sort((a, b) => a.localeCompare(b));
        setRegionOptions(regionOptions);
    }, [territorioData]);

    useEffect(() => {
        // Initialize comuna options based on selected region
        const selectedRegion = territorioData.find((item) => item.nombre === region);
        if (selectedRegion) {
            const comunaOptions = selectedRegion.provincias
                .flatMap((provincia) => provincia.comunas.map((comuna) => comuna.nombre))
                .sort((a, b) => a.localeCompare(b));
            setComunaOptions(comunaOptions);
        } else {
            setComunaOptions([]);
            setComuna(null);
        }
    }, [territorioData, region]);

    useEffect(() => {
        const fetchResponsable = async () => {
            try {
                if (user && user.idcliente) {
                    const response = await axios.get(`/api/clientes/getResponsableCliente/${user.usuario.correo}`);
                    if (response.data.length > 0) {
                        setEsResponsable(true);
                    }
                    else {
                        setEsResponsable(false);
                    }
                    
                }
            } catch (error) {
                console.error('Error fetching responsable cliente:', error);
            }
        };
        const fetchEjecutivos = async () => {
            try {
                if (user && user.idcliente) {
                    const response = await axios.get(`/api/usuariosclientes/getUsuariosclientes/${user.idcliente}`);
                    setEjecutivos(response.data);
                }
            } catch (error) {
                console.error('Error fetching usuarios clientes:', error);
            }
        };

        fetchEjecutivos();
        fetchResponsable();
    }, [user]); // Add user to dependency array so useEffect runs when user changes

    let publicUrl = process.env.PUBLIC_URL + '/';

    
    const handleSortChange = useCallback((event) => {
        const selectedOption = event.target.value;
        setSortOption(selectedOption);
        setCurrentPage(1);
    }, []);


    //if (isLoading) {
    //    return <div></div>;
    //}

    // Handler for pagination buttons
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);

        // Call fetchProperties with the new page number
        fetchProperties(pageNumber, sortOption, propertiesPerPage, palabraclave, verTodas, ejecutivo);
    };


    const handlePropertiesPerPageChange = (event) => {
        const perPage = parseInt(event.target.value);
        setPropertiesPerPage(perPage);
        setCurrentPage(1); // Reset current page when changing properties per page

        // Update total pages based on the new properties per page
        const calculatedTotalPages = Math.ceil(totalCount / perPage);
        setTotalPages(calculatedTotalPages);

        // Use the updated propertiesPerPage value in the callback
        fetchProperties(1, sortOption, perPage, palabraclave, verTodas, ejecutivo);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const newPage = currentPage - 1;
            setCurrentPage(newPage);
            fetchProperties(newPage, sortOption, propertiesPerPage, palabraclave, verTodas, ejecutivo);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            const newPage = currentPage + 1;
            setCurrentPage(newPage);
            fetchProperties(newPage, sortOption, propertiesPerPage, palabraclave, verTodas, ejecutivo);
        }
    };


    const handleBorrar = async (IdProp) => {
        try {
            const confirmed = await Swal.fire({
                title: 'Confirmacion',
                text: '¿Esta seguro que desea eliminar esta propiedad?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Eliminarla!',
                cancelButtonText: 'Cancelar',
            });

            if (confirmed.isConfirmed) {
                const response = await axios.delete(`/api/propiedades/Eliminar/${IdProp}`);
                if (response.status === 200) {
                    fetchProperties(1, sortOption, propertiesPerPage);
                } else {
                    throw new Error('Error al borrar la propiedad');
                }
            }
        } catch (error) {
            console.error('Error al borrar la propiedad:', error);
            Swal.fire('Oops!', error.message, 'error');
        }
    };

    const handleEditar = async (IdProp) => {
        const url = `/Publicarpropiedad`;
        navigate(url, { state: { IdProp }, replace: true });
    };

    const handleVerTodasChange = () => {
        setVerTodas(!verTodas);
    };

    const handleVerTodasPromoChange = () => {
        setVerTodasPromo(!verTodasPromo);
    };

    const handleEjecutivoChange = (event) => {
        setEjecutivo(event.target.value);
    };

   
    const Details = ({ item }) => {
        let subtitulo = "";
        if (item.property.tipopropiedad === 2) subtitulo = subtitulo + "Casa en ";
        if (item.property.tipopropiedad === 3) subtitulo = subtitulo + "Departamento en ";
        if (item.property.tipopropiedad === 4) subtitulo = subtitulo + "Galpon en ";
        if (item.property.tipopropiedad === 5) subtitulo = subtitulo + "Local Comercial en ";
        if (item.property.tipopropiedad === 6) subtitulo = subtitulo + "Terreno en ";
        if (item.property.tipopropiedad === 7) subtitulo = subtitulo + "Oficina en ";
        if (item.property.tipopropiedad === 8) subtitulo = subtitulo + "Parcela en ";
        if (item.property.tipopropiedad === 9) subtitulo = subtitulo + "Bodega en ";
        if (item.property.tipopropiedad === 10) subtitulo = subtitulo + "Derecho Llave en ";
        if (item.property.tipopropiedad === 11) subtitulo = subtitulo + "Estacionamiento en ";
        if (item.property.tipopublicacion === 1) subtitulo = subtitulo + "Venta";
        if (item.property.tipopublicacion === 2) subtitulo = subtitulo + "Arriendo";

        return (
            <div>
                <div className="feature-logo">
                    {item.icono &&
                        <img src={`data:image/jpeg;base64,${item.icono}`} alt="logo" className="rounded-circle"
                            style={{ maxWidth: '100%', height: 'auto' }} />
                    }
                    {!item.icono &&
                        <img className="img-profile rounded-circle" src={"/assets/img/Foto003.jpg"} alt="logo" />
                    }
                </div>
                <p className="author">
                    <i className="fa fa-user" /> {item.nombre}
                </p>
                <h6 className="readeal-top">
                    {subtitulo}
                </h6>
                <h6 className="title readeal-top">
                    {item.property.titulo} - {item.property.comuna} 
                </h6>
                <h6 className="price">{item.property.moneda} {FormatoNumero(item.property.precioactual)}</h6>
                <del>{FormatoNumero(item.property.precioanterior)}</del>
                <ul className="info-list">
                    {item.property.numhabitaciones > 0 && (
                    
                        <li>
                            <i className="fa fa-bed" /> {item.property.numhabitaciones} Habs
                        </li>
                    )}
                    {item.property.numbaños > 0 && (
                        <li>
                            <i className="fa fa-bath" /> {item.property.numbaños} baños
                        </li>
                    )}
                    {item.property.numestacionamientos > 0 && (
                        <li>
                            <i className="fa fa-car" /> {item.property.numestacionamientos} estacionamientos
                        </li>
                    )}
                    <li><img src={"/assets/img/icons/7.png"} alt="area" /> {item.property.area} Mt2</li>
                </ul>
                <ul className="contact-list">
                    <li title="Borrar">
                        <button
                            className="message"
                            onClick={() => handleBorrar(item.property.idpropiedad)}
                            style={{
                                border: "none",
                                padding: 0,
                                cursor: "pointer",
                            }}
                        >
                            <img src={"/assets/img/icons/trash-2-15.ico"} alt="img" />
                        </button>
                    </li>
                    <li title="Editar">
                        <button
                            className="message"
                            onClick={() => handleEditar(item.property.idpropiedad)}
                            style={{
                                border: "none",
                                padding: 0,
                                cursor: "pointer",
                            }}
                        >
                            <img src={"/assets/img/icons/edit-15.ico"} alt="img" />
                        </button>
                    </li>
                    <li className="readeal-top">
                        <Link className="btn btn-yellow" to={`/detallepropiedad/${item.property.idpropiedad}`}>
                            Ver Detalles
                        </Link>
                    </li>
                </ul>
            </div>
        );
    };

    const handleFiltrarpropiedades = (event) => {
        event.preventDefault();
        // Call fetchProperties with updated Consulta parameter
        fetchProperties(1, sortOption, propertiesPerPage, palabraclave, verTodas, verTodasPromo, ejecutivo);
    };

    
    const toggleSortDropdown = () => {
        setSortDropdownOpen(!sortDropdownOpen);
    };

    const toggleViewDropdown = () => {
        setViewDropdownOpen(!viewDropdownOpen);
    };

    const togglePerPageDropdown = () => {
        setPerPageDropdownOpen(!perPageDropdownOpen);
    };

    const dropdownStyle = { fontSize: '0.7rem', minWidth: '5rem' };

    return (
        <div className="search-page-wrap pd-top-100 pd-bottom-70">
            {/* Render spinner if loading */}
            {isLoading && (
                <div className="preloader" id="preloader">
                    <div className="preloader-inner">
                        <div className="spinner">
                            <div className="dot1"></div>
                            <div className="dot2"></div>
                        </div>
                    </div>
                </div>
            )}
            <div className="search-container">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 sitebar">
                             <h6 className="filter-title mb-4"><img className="mr-3" src={publicUrl + "assets/img/icons/18.png"} alt="img" />Filtros</h6>
                            <div className="widget widget-sidebar-search-wrap">
                                <form className="" onSubmit={handleFiltrarpropiedades}>
                                    <div className="widget-sidebar-item-wrap rld-single-input">
                                        <div className="widget-sidebar-label">
                                            <Label for="region">Región</Label>
                                            <div className="widget-sidebar-input">
                                        <Input
                                            style={{ width: '200px' }}
                                            type="select"
                                            id="region"
                                            value={region}
                                            onChange={(event) => setRegion(event.target.value)}
                                            required
                                        >
                                            <option value="">Selecciona una region</option> 
                                            {regionOptions.map((item, index) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                            </Input>
                                            </div>
                                        </div>
                                        <div className="widget-sidebar-item-wrap rld-single-input">
                                        <Label for="comuna">Comuna</Label>
                                        <Input
                                            type="select"
                                            id="comuna"
                                            value={comuna || ""}
                                            onChange={(event) => setComuna(event.target.value)}
                                            required
                                        >
                                            <option value="">Selecciona una comuna</option>
                                            {comunaOptions.map((item, index) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </Input>
                                        </div>
                                        <div className="widget-sidebar-item-wrap rld-single-input">
                                            <Label for="palabraclave">Palabra Clave en titulo (opcional)</Label>
                                            <Input
                                                style={{height:'35px'} }
                                                type="text"
                                                id="palabraclave"
                                                value={palabraclave}
                                                onChange={(event) => setPalabraClave(event.target.value)} // Update palabraclave state here
                                            >
                                            </Input>
                                        </div>
                                        <br></br>
                                        {esresponsable && (
                                        <div className="widget-sidebar-item-wrap rld-single-input">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ verticalAlign: 'middle' }}>Todos los Ejecutivos</span>
                                                <Input
                                                    type="checkbox"
                                                    checked={verTodas}
                                                    onChange={handleVerTodasChange}
                                                    style={{
                                                        transform: 'scale(0.5)', // Adjust the scale value as needed
                                                        marginLeft: '160px', // Add space between the label and checkbox
                                                        verticalAlign: 'middle', // Align the checkbox vertically with the text
                                                        width: '15px',
                                                        border: '1px solid black', // Add a black border
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        )}
                                        <br></br>
                                        {esresponsable && (
                                            <div className="widget-sidebar-item-wrap rld-single-input">
                                                <div className="ltn__search-by-place-form">
                                                    <Label for="ejecutivo">Ejecutivo</Label>
                                                    <Input
                                                        type="select"
                                                        value={ejecutivo}
                                                        onChange={handleEjecutivoChange}
                                                        disabled={!verTodas} // Disable if checkbox is not checked
                                                    >
                                                        <option value="">Seleccione un ejecutivo</option>
                                                        {ejecutivos.map((ejecutivo) => (
                                                            <option key={ejecutivo.email} value={ejecutivo.email}>{ejecutivo.email}</option>
                                                        ))}
                                                    </Input>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <br></br>
                                    <div className="widget-sidebar-item-wrap rld-single-input">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ verticalAlign: 'middle' }}>Solo Promocionadas</span>
                                            <Input
                                                type="checkbox"
                                                checked={verTodasPromo}
                                                onChange={handleVerTodasPromoChange}
                                                style={{
                                                    transform: 'scale(0.5)', // Adjust the scale value as needed
                                                    marginLeft: '160px', // Add space between the label and checkbox
                                                    verticalAlign: 'middle', // Align the checkbox vertically with the text
                                                    width: '15px',
                                                    border: '1px solid black', // Add a black border
                                                }}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="btn-wrap text-center">
                                <button className="btn btn-yellow" onClick={handleFiltrarpropiedades}>
                                    <span className="left">
                                        <i className="fa fa-search" />
                                    </span>
                                    Filtrar propiedades
                                </button>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8">
                            <div className="row mb-3">
                                <div className="col-md-4 col-sm-3">
                                    <h6 className="filter-title mt-3 mb-lg-0">{totalCount} {totalCount === 1 ? 'propiedad' : 'propiedades'}</h6>
                                </div>
                                <div className="col-md-8 col-sm-9 d-flex justify-content-end">
                                    <div style={{ display: 'flex', alignItems: 'right' }}>
                                        <Dropdown isOpen={sortDropdownOpen} toggle={toggleSortDropdown} className="mr-3 mb-3">
                                            <DropdownToggle caret color="primary" size="sm" style={dropdownStyle}>
                                                Orden Datos
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem value={1} onClick={handleSortChange} style={dropdownStyle}>Recientes</DropdownItem>
                                                <DropdownItem value={2} onClick={handleSortChange} style={dropdownStyle}>Precio Asc</DropdownItem>
                                                <DropdownItem value={3} onClick={handleSortChange} style={dropdownStyle}>Precio Desc</DropdownItem>
                                                <DropdownItem value={4} onClick={handleSortChange} style={dropdownStyle}>Tamaño Asc</DropdownItem>
                                                <DropdownItem value={5} onClick={handleSortChange} style={dropdownStyle}>Tamaño Desc</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>

                                        <Dropdown isOpen={viewDropdownOpen} toggle={toggleViewDropdown} className="mr-3 mb-3">
                                            <DropdownToggle caret color="primary" size="sm" style={dropdownStyle}>
                                                tipo Vista
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem value={1} onClick={(event) => settipoVista(event.target.value)} style={dropdownStyle}>Listado</DropdownItem>
                                                <DropdownItem value={2} onClick={(event) => settipoVista(event.target.value)} style={dropdownStyle}>Grid</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>

                                        <Dropdown isOpen={perPageDropdownOpen} toggle={togglePerPageDropdown} className="mb-3">
                                            <DropdownToggle caret color="primary" size="sm" style={dropdownStyle}>
                                                # Props x Pag
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem value={10} onClick={handlePropertiesPerPageChange} style={dropdownStyle}>10</DropdownItem>
                                                <DropdownItem value={20} onClick={handlePropertiesPerPageChange} style={dropdownStyle}>20</DropdownItem>
                                                <DropdownItem value={50} onClick={handlePropertiesPerPageChange} style={dropdownStyle}>50</DropdownItem>
                                                <DropdownItem value={100} onClick={handlePropertiesPerPageChange} style={dropdownStyle}>100</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                            {tipovista === '2' && 
                                <div className="row justify-content-center">
                                    {properties.map((property, i) => (
                                        <div key={i} className="col-xl-4 col-sm-6">
                                            <div className="single-feature">
                                                <Thumb imageData={property.base64Data} height={200} hasPromo={property.property.nivelpromo} />
                                                <div className="details">
                                                    <Details item={property} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                            {tipovista === '1'  && 
                                properties.map((property, i) => (
                                    <div key={i} className="single-feature style-two">
                                        <Thumb imageData={property.base64Data} height={400} hasPromo={property.property.nivelpromo} />
                                        <div className="details">
                                            <div className="details-wrap">
                                                <Details item={property} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="pagination-wrap text-center">
                                <div className="pagination-wrap text-center">
                                    <button className="btn btn-yellow" onClick={handlePreviousPage} disabled={currentPage === 1}>Anterior</button>
                                    {pageNumbers.map((number) => (
                                        <button
                                            className={`btn btn-yellow ${number === currentPage ? 'highlighted-button' : ''}`}
                                            key={number}
                                            onClick={() => handlePageChange(number)}
                                        >
                                            {number}
                                        </button>
                                    ))}

                                    <button className="btn btn-yellow" onClick={handleNextPage} disabled={currentPage === totalPages}>Siguiente</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mispropiedades;
